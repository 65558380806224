import "../../styles/comoponents_pages/navigation.scss";
import { GoSearch } from "react-icons/go";
import avatar from "../../assets/images/avatar.jpg";
import { useEffect } from "react";
import CloseSideBar from "../../assets/icons/CloseSideBar";
import RcTooltip from "../ToolTip";
import { useDispatch, useSelector } from "react-redux";
import {
  setGlobalSearch,
  setShowFullSideBar,
} from "../../redux/slices/AppLayout";
import OpenSideBar from "../../assets/icons/OpenSideBar";
// import NotificationIcon from "../../assets/icons/NotificationIcon";
import { IoIosArrowDown } from "react-icons/io";

export default function Navigation({
  pageTitle,
  showNotifications,
  setShowNotifications,
  showSettingsModal,
  setShowSettingsModal,
}) {
  const dispatch = useDispatch();
  const { showFullSidebar, globalSearch } = useSelector(
    (state) => state.appLayout
  );

  const showSearch = ["Customers", "Orders"];

  useEffect(() => {
    setShowNotifications(false);
    setShowSettingsModal(false);
  }, [setShowSettingsModal, setShowNotifications]);

  return (
    <>
      <div
        className="navigation-container"
        style={{ left: showFullSidebar ? "20%" : "5%" }}
        onClick={() => setShowNotifications(false)}
      >
        <RcTooltip
          direction="right"
          title={showFullSidebar ? "Close Sidebar" : "Open Sidebar"}
        >
          <section
            className="close-open-sidebar"
            onClick={() => dispatch(setShowFullSideBar(!showFullSidebar))}
          >
            {showFullSidebar ? <CloseSideBar /> : <OpenSideBar />}
          </section>
        </RcTooltip>

        <section className="profile-section">
          {showSearch.includes(pageTitle) && (
            <div className="search-container">
              <input
                type="text"
                placeholder="Search"
                onChange={(e) => dispatch(setGlobalSearch(e.target.value))}
                value={globalSearch}
              />
              <GoSearch className="search-glass" />
            </div>
          )}

          {/* <IoSettingsOutline /> */}
          <div className="notification-icon">
            {/* <div
              onClick={(e) => {
                e.stopPropagation();
                setShowNotifications(!showNotifications);
              }}
            >
              <NotificationIcon />
            </div> */}
          </div>
          <div className="profile-settings">
            <div className="avatar">
              <img
                src={avatar}
                draggable="false"
                alt=""
                onClick={(e) => {
                  e.stopPropagation();
                  setShowSettingsModal(e.currentTarget);
                }}
              />
            </div>
            <IoIosArrowDown className="arrow-down-icon" />
          </div>
        </section>
      </div>
    </>
  );
}
