import "../../styles/comoponents_pages/cards.scss";

export default function Card({ icon, title, count, percentage }) {
  return (
    <div className="card-container">
      <div className="icon">{icon}</div>
      <div className="title">{title}</div>
      <div className="card-footer">
        <div className="count">{count}</div>
        {percentage && (
          <div
            className={
              percentage >= 20
                ? "percentage high-percentage"
                : "percentage low-percentage"
            }
          >
            {"+" + percentage ? percentage.toFixed(2) + "%" : ""}
          </div>
        )}
      </div>
    </div>
  );
}
