import EmptyCatalogue from "../../../components/empty_states/EmptyCatalogue";
import ProductCard from "./ProductCard";
import { useFetchProducts } from "../../../services/products";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useInView } from "react-intersection-observer";

export default function ProductsCatalogue({ search }) {
  const { ref, inView } = useInView();
  const [products, setProducts] = useState([]);
  const { catFilter } = useSelector((state) => state.productSlice);
  // const { data, status } = useGetProduct(catFilter);

  const { data, status, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useFetchProducts({ catId: catFilter, search: search.length > 3 ? search : '' });

  useEffect(() => {
    if (data) {
      const products = data.pages.flatMap((page) => page.data.data);
      setProducts(products);
    }
  }, [data]);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  return (
    <>
      <div className="product-catalogue-container">
        {status === "pending" ? (
          <div className="spinner-container">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : data && products.length ? (
          products.map((product, i) => {
            return <ProductCard key={i} product={product} />;
          })
        ) : (
          <EmptyCatalogue
            title="No Products yet"
            body="All products will appear here"
            action="Add new product"
          />
        )}
        {isFetchingNextPage && (
          <div className="spinner-border" role="status"></div>
        )}
      </div>
      {!isFetchingNextPage && status !== "pending" && (
        <button style={{ opacity: 0 }} onClick={fetchNextPage} ref={ref}>
          Load More
        </button>
      )}
    </>
  );
}
