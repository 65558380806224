import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import requestNew from "../app/requestNew";

export const useGetCustomers = (search) => {
  const getCustomers = ({ pageParam }) => {
    const data = requestNew({
      url: "admin/customer/all/fetch",
      method: "GET",
      params: {
        search,
        page: pageParam || 1,
      },
    });
    return data;
  };
  return useInfiniteQuery({
    queryKey: ["customers", search],
    queryFn: getCustomers,
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      const { data } = lastPage;
      if (!lastPage.data || lastPage.data.length < data.per_page) {
        return false;
      }
      return allPages.length + 1;
    },
  });
};

export const useGetCustomer = (id) => {
  return useQuery({
    queryKey: [`customer-${id}`, id],
    queryFn: async () => {
      const data = await requestNew({
        url: `admin/customer/${id}/show`,
        method: "GET",
      });
      return data.data;
    },
    enabled: !!id,
  });
};

export const useGetCustomerOrders = (id) => {
  const getCustomerOrders = ({ pageParam }) => {
    const data = requestNew({
      url: `admin/customer/${id}/order/history`,
      method: "GET",
      params: {
        page: pageParam || 1,
      },
    });
    return data;
  };
  return useInfiniteQuery({
    queryKey: [`customer-${id}-orders`, id],
    queryFn: getCustomerOrders,
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      const { data } = lastPage;
      if (!lastPage.data || lastPage.data.length < data.per_page) {
        return false;
      }
      return allPages.length + 1;
    },
    enabled: !!id,
  });
};
