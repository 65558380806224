import React from "react";
import { IoSearchOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setCatFilter,
  setDelProduct,
  setToggleSelectMode,
} from "../../../redux/slices/ProductSlice";
import "../../../styles/comoponents_pages/modal.scss";
import DeleteProduct from "./DeleteProduct";
// import toast, { Toaster } from "react-hot-toast";

// const notify = () => toast("Here is your toast.");

const categories = [
  {
    id: "1",
    title: "Coffee",
  },
  {
    id: "2",
    title: "Pastries",
  },
];

function ProductHeading({ search, setSearch }) {
  const [modalShow, setModalShow] = React.useState(false);
  const { selectMode, deleteProductArr, catFilter } = useSelector(
    (state) => state.productSlice
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div className="product-heading-container">
      {/* <div>
        <button onClick={notify}>Make me a toast</button>
        <Toaster />
      </div> */}
      {categories.map((cat) => {
        return (
          <span
            key={cat.id}
            className={`categories ${
              catFilter === cat.id ? "active-cat" : "inactive-cat"
            }`}
            onClick={() => dispatch(setCatFilter(cat.id))}
          >
            {cat.title}
          </span>
        );
      })}
      {/* <span className="active categories">Coffee</span>
      <span className="inactive categories">Pastries</span> */}
      <div className="search-products">
        <input
          type="text"
          placeholder="Search"
          value={search}
          setSearch={setSearch}
          onChange={(e) => setSearch(e.target.value)}
        />
        <IoSearchOutline className="search-icon" />
      </div>
      <div className="select-products">
        <input
          type="checkbox"
          checked={selectMode}
          onChange={() => dispatch(setToggleSelectMode())}
        />
        <label className="select-products-txt">Select products</label>
      </div>
      {!selectMode ? (
        <div className="add-new">
          <button onClick={() => navigate("/manager/products/create")}>
            Add new product
          </button>
        </div>
      ) : (
        <div className="product-option">
          <span
            className="opt-btn delete-btn"
            style={{ opacity: deleteProductArr.length ? "1" : "0.5" }}
            onClick={() =>
              deleteProductArr.length ? setModalShow(true) : null
            }
          >
            Delete
          </span>
          <span
            className="opt-btn cancel-btn"
            onClick={() => {
              dispatch(setDelProduct([]));
              dispatch(setToggleSelectMode());
            }}
          >
            Cancel
          </span>
          <DeleteProduct
            modalShow={modalShow}
            setModalShow={setModalShow}
            productId={deleteProductArr}
          />
        </div>
      )}
    </div>
  );
}

export default ProductHeading;
