import { useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import { useGetCharts } from "../../services/dashboard";
import LineChart from "./LineChart";
import { useSelector } from "react-redux";

export default function Analysis() {
  const { chartFilter } = useSelector((state) => state.appLayout);
  const [chart, setChart] = useState({
    revenue: null,
    order_summary: null,
  });
  const { data, status } = useGetCharts(chartFilter);

  useEffect(() => {
    if (data) {
      const revenue = {
        keys: Object.keys(data.data.revenue),
        values: Object.values(data.data.revenue),
      };
      const summary = {
        keys: Object.keys(data.data.order_summary),
        values: Object.values(data.data.order_summary),
      };
      setChart({
        revenue: revenue,
        order_summary: summary,
      });
    }
  }, [data]);

  return (
    <>
      <Head title="Dashboard" />
      {status === "pending" ? (
        <section className="loader" style={{ height: "350px" }}>
          <div className="spinner-border" role="status"></div>
        </section>
      ) : (
        <div className="question-analysis-container">
          <section className="block-section">
            <div className="chart-section">
              <div className="line-chart">
                {chart.revenue && (
                  <LineChart title="Revenue" chartData={chart.revenue} />
                )}

                <span className="chart-divider-container" />
                {chart.order_summary && (
                  <LineChart
                    title="Order summary"
                    chartData={chart.order_summary}
                  />
                )}
              </div>
            </div>
            {/* <TopCourses /> */}
          </section>
        </div>
      )}
    </>
  );
}
