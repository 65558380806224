import React, { useRef, useState } from "react";
import "../../styles/comoponents_pages/otp.scss";

const OTPInput = ({ length = 4, onComplete }) => {
  const inputRef = useRef(Array(length).fill(null));

  const [OTP, setOTP] = useState(Array(length).fill(""));

  const handleTextChange = (input, index) => {
    const newPin = [...OTP];
    newPin[index] = input;
    setOTP(newPin);

    if (input.length === 1 && index < length - 1) {
      inputRef.current[index + 1]?.focus();
    }

    if (input.length === 0 && index > 0) {
      inputRef.current[index - 1]?.focus();
    }

    if (newPin.every((digit) => digit !== "")) {
      onComplete(newPin.join(""));
    }
  };

  return (
    <div className="opt-container">
      {Array.from({ length }, (_, index) => (
        <div className="otp-field">
          <input
            key={index}
            type="text"
            maxLength={1}
            value={OTP[index]}
            onChange={(e) => handleTextChange(e.target.value, index)}
            ref={(ref) => (inputRef.current[index] = ref)}
            className="otp-input"
            style={{ marginRight: index === length - 1 ? "0" : "10px" }}
            placeholder="-"
            autoFocus={index === 0 ? true : false}
          />
        </div>
      ))}
    </div>
  );
};

export default OTPInput;
