import React from "react";
import CenteredPopup from "../../../components/modal/Modal";
import FactCheck from "../../../assets/icons/FactCheck";
import { useNavigate } from "react-router-dom";

function ProductConfirm({ modalShow, setModalShow, status }) {
  const navigate = useNavigate();
  return (
    <CenteredPopup
      show={modalShow}
      onHide={() => setModalShow(false)}
      children={
        <>
          {status === "pending" && (
            <div>
              <div className="spinner-border" role="status"></div>
              <h4>Processing</h4>
              <p>Hold on a sec! You’ll product list is being updated...</p>
            </div>
          )}
          {status === "success" && (
            <div>
              <FactCheck />
              <h4>Success</h4>
              <p>Product added successfully!</p>
            </div>
          )}
          {status === "error" && (
            <div>
              <h4>Error</h4>
              <p>Enexpected error occcured. Please try again</p>
            </div>
          )}
        </>
      }
      footer={
        <>
          {status === "success" && (
            <>
              <button onClick={() => navigate("/manager/products")}>
                Go back to Products
              </button>
            </>
          )}
          {status === "error" && (
            <>
              <span
                className="footer-btn cancel-btn"
                onClick={() => setModalShow(false)}
              >
                Cancel
              </span>
            </>
          )}
        </>
      }
    />
  );
}

export default ProductConfirm;
