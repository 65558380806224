import React, { useState } from "react";
import Uppy from "@uppy/core";
import { DashboardModal } from "@uppy/react";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import XHRUpload from "@uppy/xhr-upload";
import { useQueryClient } from "@tanstack/react-query";
import { STORAGE_KEYS } from "../../../../constants/constants";

function UploadAvatar({ open, setOpen }) {
  const queryClient = useQueryClient();
  const accessToken = JSON.parse(
    localStorage.getItem(STORAGE_KEYS.AUTH_TOKEN) || "null"
  );
  const [, setUploadedData] = useState(null);
  const handleUploadSuccess = (file) => {
    setUploadedData(file.data);

    queryClient.invalidateQueries(["imageData"]);
  };
  const uppy = new Uppy({
    autoProceed: false,
    restrictions: {
      allowedFileTypes: null,
    },
    meta: {},
  }).use(XHRUpload, {
    fieldName: "avatar",
    endpoint: `${process.env.REACT_APP_API_BASE_URL}/api/avatar/update`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    method: "POST",
  });

  uppy.on("complete", (result) => {
    if (result.successful) {
      handleUploadSuccess(result.successful[0]);
    }
  });

  // uppy.use(XHRUpload, {
  //   endpoint: "avatar/update",
  //   method: "POST",
  //   fieldName: "avatar",
  // });
  return (
    <div>
      <DashboardModal
        uppy={uppy}
        onComplete={handleUploadSuccess}
        id={String(Date.now())}
        open={open}
        closeModalOnClickOutside
        proudlyDisplayPoweredByUppy={false}
        onRequestClose={() => setOpen(false)}
        showRemoveButtonAfterComplete={false}
        animateOpenClose={false}
      />
    </div>
  );
}

export default UploadAvatar;
