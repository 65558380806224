import axios from "axios";
import { STORAGE_KEYS } from "../constants/constants";

async function requestNew(options) {
  const accessToken = JSON.parse(
    localStorage.getItem(STORAGE_KEYS.AUTH_TOKEN) || "null"
  );

  const headers = accessToken
    ? {
        Authorization: `Bearer ${accessToken}`,
      }
    : undefined;

  const client = axios.create({
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/`,
    headers,
  });

  // request handler
  const onSuccess = (response) => {
    const { data } = response;
    return data;
  };

  // error handler
  function onError(error) {
    return Promise.reject(error.response);
  }

  try {
    const response = await client(options);
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
}
export default requestNew;
