import React from "react";

function CompletedIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.11335 12.8882L14.0637 6.93783L12.9246 5.80771L8.11335 10.619L5.70483 8.21048L4.57968 9.34062L8.11335 12.8882ZM9.33377 17.4724C8.16779 17.4724 7.06959 17.2505 6.03914 16.8066C5.00868 16.3627 4.10989 15.7573 3.34277 14.9901C2.57564 14.223 1.97015 13.3244 1.52629 12.2942C1.08241 11.2641 0.860474 10.166 0.860474 9.00004C0.860474 7.82482 1.08241 6.72198 1.52629 5.69152C1.97015 4.66106 2.57547 3.76453 3.34224 3.00192C4.10902 2.23931 5.00756 1.63558 6.03787 1.19073C7.06819 0.745867 8.16643 0.523438 9.33258 0.523438C10.508 0.523438 11.611 0.745777 12.6418 1.19046C13.6725 1.63515 14.5691 2.23866 15.3315 3.00098C16.094 3.76328 16.6976 4.65972 17.1423 5.69027C17.5871 6.72081 17.8095 7.82392 17.8095 8.99958C17.8095 10.1659 17.587 11.2642 17.1422 12.2946C16.6973 13.325 16.0936 14.2237 15.331 14.9905C14.5684 15.7574 13.672 16.3627 12.6418 16.8066C11.6117 17.2505 10.509 17.4724 9.33377 17.4724ZM9.33331 15.8942C11.2552 15.8942 12.8848 15.224 14.2221 13.8835C15.5593 12.543 16.2279 10.915 16.2279 8.99958C16.2279 7.07768 15.5593 5.4481 14.2221 4.11083C12.8848 2.77358 11.2547 2.10496 9.33164 2.10496C7.41789 2.10496 5.79063 2.77358 4.44985 4.11083C3.10907 5.4481 2.43868 7.07824 2.43868 9.00125C2.43868 10.915 3.10893 12.5423 4.44943 13.883C5.78992 15.2238 7.41788 15.8942 9.33331 15.8942Z"
        fill="#D0965A"
      />
    </svg>
  );
}

export default CompletedIcon;
