import React, { useState } from "react";
import "../styles/comoponents_pages/input.scss";
import { BsEye, BsEyeSlash } from "react-icons/bs";

function Input({ type, label, input, setInput }) {
  const [focus, setFocus] = useState(false);
  // const [input, setInput] = useState("");
  const [view, setView] = useState(false);

  const focusMode = focus || input.length > 0;
  const isPassword = type === "password";

  return (
    <div
      className="input-containers"
      onClick={() => setFocus(true)}
      style={{ position: "relative" }}
    >
      <input
        type={isPassword ? (view ? "text" : type) : type}
        focus={focusMode}
        autoFocus={focusMode}
        style={{
          height: focusMode ? "34px" : "54px",
          marginTop: focusMode ? "20px" : "0",
        }}
        onBlur={() => setFocus(false)}
        onChange={(e) => setInput(e.target.value)}
      />
      <label
        className="input-label"
        style={{ position: "absolute", top: focusMode ? "0" : "30%" }}
      >
        {label}
      </label>
      {isPassword && (
        <div
          style={{
            position: "absolute",
            right: "0",
            top: "20%",
            marginRight: "10px",
            zIndex: 3,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {view.confirm ? (
            <BsEyeSlash
              className="eye pointer"
              onClick={() => setView(!view)}
            />
          ) : (
            <BsEye className="eye pointer" onClick={() => setView(!view)} />
          )}
        </div>
      )}
    </div>
  );
}

export default Input;
