import "./../../../styles/comoponents_pages/settings.scss";
import { BiCog, BiEditAlt, BiLogIn } from "react-icons/bi";
import { Link } from "react-router-dom";
import { Menu } from "@mui/material";
import { useGetProfile } from "../../../services/auth";
import AvatarWithInitials from "../../AvataInitials/AvatarWithInitials";
import { useDispatch } from "react-redux";
import { setSettingPage } from "../../../redux/slices/AppLayout";

export default function SettingsSummaryModal({ anchorEl, setAnchorEl }) {
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { data, status } = useGetProfile();

  const handleLogOut = () => {
    localStorage.clear();
    window.location.reload();
  };
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      className="settings-modal"
      PaperProps={{
        style: {
          width: 200,
        },
      }}
    >
      {status === "pending" ? (
        <div className="spinner-border" role="status"></div>
      ) : (
        <div className="settings_summary_modal_container">
          <div className="profile_picture_cont">
            <div>
              {data && data.user.avatar ? (
                <img src={data.user.avatar} alt="" />
              ) : (
                <AvatarWithInitials name={data.user.full_name} />
              )}
            </div>

            <div className="user-details">
              <p className="user-name">{data && data.user.full_name}</p>
              <p className="profile_picture_cont_email">
                {data && data.user.email}
              </p>
            </div>
          </div>

          <Link to="/manager/settings" className="item_link">
            <div className="items_list active_item">
              <BiCog className="settings_icon" />
              <p>Settings</p>
            </div>
          </Link>

          <Link to="/manager/settings" className="item_link">
            <div
              className="items_list active_item"
              onClick={() => {
                dispatch(setSettingPage("preference"));
              }}
            >
              <BiEditAlt className="settings_icon" />
              <p>Preference</p>
            </div>
          </Link>

          <div className="items_list active_item" onClick={handleLogOut}>
            <BiLogIn className="settings_icon2" />
            <p>Log Out</p>
          </div>
        </div>
      )}
    </Menu>
  );
}
