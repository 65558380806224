import React, { useState } from "react";
import OTPInput from "../../../components/otp";

function EnterOtp({ onVerify }) {
  const [otp, setOtp] = useState("");
  return (
    <div className="verify-otp">
      <header>
        <h2>Enter OTP</h2>
        <p>
          A 6-digit OTP (one time password) has been sent to your e-mail for
          verification.
        </p>
      </header>
      <OTPInput length={6} onComplete={(pin) => setOtp(pin)} />
      <div className="resend-code">
        <div>
          Didn’t get a code? <span className="primary-color">Resend</span>
        </div>
        <span>2:00</span>
      </div>

      <button className="verify-btn" onClick={() => onVerify(otp)}>
        Verify code
      </button>
    </div>
  );
}

export default EnterOtp;
