import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../../components/Header";
import OrdersTable from "../../../components/admin/orders/OrdersTable";
import LayoutWithSidebar from "../../../layout/template/LayoutWithSidebar";
import { setOrderStatus } from "../../../redux/slices/Orders";
import { useState } from "react";
import dayjs from "dayjs";

export default function Orders() {
  const dispatch = useDispatch();
  const [date, setDate] = useState();
  const { orderStatus } = useSelector((state) => state.orders);
  const headerOptions = [
    {
      id: "1",
      title: "All",
      onClick: () => {
        dispatch(setOrderStatus(null));
      },
      isActive: orderStatus === null,
    },
    {
      id: "3",
      title: "Processing",
      onClick: () => {
        dispatch(setOrderStatus("processing"));
      },
      isActive: orderStatus === "processing",
    },
    {
      id: "3",
      title: "On the way",
      onClick: () => {
        dispatch(setOrderStatus("in-transit"));
      },
      isActive: orderStatus === "in-transit",
    },
    {
      id: "4",
      title: "Delivered",
      onClick: () => {
        dispatch(setOrderStatus("delivered"));
      },
      isActive: orderStatus === "delivered",
    },
  ];

  const handleDate = (newDate) => {
    console.log(dayjs(newDate[0]).format("YYYY-MM-DD"));
    dispatch(
      setOrderStatus({
        type: "date",
        start_date: dayjs(newDate[0]).format("YYYY-MM-DD"),
        end_date: dayjs(newDate[1]).format("YYYY-MM-DD"),
      })
    );
    setDate(newDate);
  };
  return (
    <LayoutWithSidebar pageTitle="Orders">
      <div className="orders-page">
        <PageHeader
          options={headerOptions}
          title="Orders"
          handleDate={handleDate}
          value={date}
        />
        <OrdersTable />
      </div>
    </LayoutWithSidebar>
  );
}
