import { useNavigate } from "react-router-dom";
import React from "react";
import Form from "react-bootstrap/Form";
import DeleteProduct from "./DeleteProduct";
import EditProduct from "./EditProduct";
import { useSelector, useDispatch } from "react-redux";
import { setDelProduct } from "../../../redux/slices/ProductSlice";

export default function ProductCard({ product }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = React.useState(false);
  const [editModal, setEditModal] = React.useState("");
  const { deleteProductArr, selectMode } = useSelector(
    (state) => state.productSlice
  );

  const handleCheckboxChange = (id) => {
    dispatch(setDelProduct(id));
  };

  return (
    <div className="product-card">
      <div
        className="course-image"
        onClick={() => {
          navigate(`${product.id}`);
        }}
      >
        <img src={product.product_images[0].image} alt={product.title} />
      </div>

      {selectMode && (
        <Form className="checkbox-container">
          <Form.Check
            type="checkbox"
            id="checkbox-example"
            checked={deleteProductArr.includes(product.id)}
            onChange={() => handleCheckboxChange(product.id)}
          />
        </Form>
      )}
      <div
        className="description"
        onClick={() => {
          navigate(`${product.id}`);
        }}
      >
        <div className="title-price">
          <h5>{product.name}</h5>
          <h5>${product.price}</h5>
        </div>
        <p>{product.description}</p>
      </div>
      <div className="card-footer">
        <span className="delete-product" onClick={() => setModalShow(true)}>
          Delete
        </span>
        <span
          className="edit-product"
          onClick={() => {
            setEditModal(product.id);
            console.log(product);
          }}
        >
          Edit
        </span>
      </div>
      <DeleteProduct
        modalShow={modalShow}
        setModalShow={setModalShow}
        productId={product.id}
      />
      {product && product.id === editModal ? (
        <EditProduct
          opendEdit={product.id === editModal}
          setOpenEdit={setEditModal}
          productId={product.id}
          product={product}
        />
      ) : null}
    </div>
  );
}
