import avatar from "../../../assets/images/emoji.svg";
import "../../../styles/comoponents_pages/businessProfile.scss";
import { BiImport } from "react-icons/bi";
import React, { useEffect, useRef, useState } from "react";
import {
  useGetBusinesssProfile,
  useUpdateBusinessProfile,
} from "../../../services/auth";
import toast from "react-hot-toast";
import ReactGoogleAutocomplete from "react-google-autocomplete";

const emptyBusiness = {
  business_name: "",
  store_location: "",
  business_email: "",
  country: "",
  phone_number: "",
  business_logo: "",
  lng: null,
  lat: null,
};

const notify = (msg) => toast(msg);

export default function BusinessProfile() {
  const imageInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState([]);
  const [isPresentLocation] = useState(false);

  const [business, setBusiness] = useState({});

  const { data, status } = useGetBusinesssProfile();

  const updateProfile = useUpdateBusinessProfile();

  const { isPending } = updateProfile;

  useEffect(() => {
    if (data) {
      if (data.business) {
        setBusiness(data.business ? data.business : emptyBusiness);
      }
    }
  }, [data]);

  const handleClick = () => {
    imageInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files;
    if (file) {
      setSelectedImage([...file]);
    }
    const files = Array.from(e.target.files);
    const urls = files.map((file) => URL.createObjectURL(file));
    setBusiness({ ...business, business_logo: urls[0] });

    // Revoke object URLs when the component is unmounted
    return () => {
      urls.forEach((url) => URL.revokeObjectURL(url));
    };
  };

  const handleUpdateBusiness = () => {
    const formData = new FormData();
    formData.append("business_name", business.business_name);
    formData.append("store_location", business.store_location);
    formData.append("business_email", business.business_email);
    formData.append("phone_number", business.phone_number);
    formData.append("lat", business.lat);
    formData.append("lng", business.lng);
    if (selectedImage.length) {
      formData.append("business_logo", selectedImage[0]);
    }
    updateProfile.mutate(formData, {
      onSuccess: (data) => {
        notify(data.message);
      },
    });
  };

  const handleAutoComplete = (place) => {
    const lat = place?.geometry?.location?.lat();
    const lng = place?.geometry?.location?.lng();
    const store_location = place?.formatted_address;
    setBusiness((prev) => {
      return { ...prev, lat, lng, store_location };
    });
  };

  // const handleUsePresentLocation = () => {
  //   navigator.geolocation.getCurrentPosition((position) => {
  //     setCoordinates({
  //       lat: position.coords.latitude,
  //       lng: position.coords.longitude,
  //     });
  //   });
  // };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      console.log({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
  }, []);

  // useEffect(() => {
  //   if (isPresentLocation) {
  //     handleUsePresentLocation();
  //   }
  // }, [isPresentLocation]);

  return (
    <>
      {status === "pending" ? (
        <section className="loader" style={{ height: "350px" }}>
          <div className="spinner-border" role="status"></div>
        </section>
      ) : (
        business && (
          <div className="profile_container">
            <div className="businessprofile_container_grid">
              <div className="business_profile_div1">
                <h3>Business Profile</h3>
                <p>
                  Here you see details about your
                  <br /> profile{" "}
                </p>
                <h4>Edit Profile</h4>
              </div>

              <div className="business_profile_inpts">
                <div className="Business_profile_inpts_innerd2">
                  <label htmlFor="">
                    Business name <span class="text-danger">*</span> <br />
                    <input
                      type="text"
                      onChange={(e) =>
                        setBusiness({
                          ...business,
                          business_name: e.target.value,
                        })
                      }
                      value={business.business_name}
                    />
                  </label>
                </div>

                <div className="Business_profile_inpts_innerd3">
                  <label htmlFor="">
                    Store location <span class="text-danger">*</span> <br />
                    <input
                      type="text"
                      value={business.store_location}
                      onChange={(e) =>
                        setBusiness({
                          ...business,
                          store_location: e.target.value,
                        })
                      }
                    />
                  </label>
                </div>

                <div className="Business_profile_inpts_innerd3">
                  <label htmlFor="">
                    Business email <span class="text-danger">*</span> <br />
                    <input
                      type="text"
                      value={business.business_email}
                      onChange={(e) =>
                        setBusiness({
                          ...business,
                          business_email: e.target.value,
                        })
                      }
                    />
                  </label>
                </div>

                <div className="Business_profile_inpts_innerd3">
                  <label htmlFor="">
                    select country <span class="text-danger">*</span> <br />
                    <select name="" id="">
                      <option value="">Nigeria</option>
                    </select>
                  </label>
                </div>

                <div className="country_select_container">
                  <div className="country_select_container_div1">
                    <span className="country_code">+1</span>
                  </div>
                  <div className="business_profile_inpts_innerd3">
                    <label htmlFor="">
                      <input
                        type="text"
                        placeholder="000 0000 0000"
                        value={business.phone_number}
                        onChange={(e) =>
                          setBusiness({
                            ...business,
                            phone_number: e.target.value,
                          })
                        }
                      />
                    </label>
                  </div>
                </div>

                <div className="Business_profile_inpts_innerd3">
                  <label htmlFor="">
                    Location <span class="text-danger">*</span> <br />
                    {isPresentLocation ? (
                      <div>Present Location</div>
                    ) : (
                      <ReactGoogleAutocomplete
                        style={{ backgroundColor: "transparent" }}
                        apiKey={process.env.REACT_APP_GOOGLE_APIKEY}
                        onPlaceSelected={(place) => {
                          handleAutoComplete(place);
                        }}
                        options={{
                          types: ["address"],
                        }}
                        defaultValue=""
                      />
                    )}
                  </label>
                  {/* <div className="input-container">
                    <input
                      type="checkbox"
                      checked={isPresentLocation}
                      onChange={() => setIsPresentLocation(!isPresentLocation)}
                    />
                    <p>Use present location</p>
                  </div> */}
                </div>
              </div>
            </div>
            <hr className="profile_container_hr" />
            <div className="businessprofile_container_grid2">
              <div className="account_profile_div3">
                <h3>Logo </h3>
                <p>Select your company's logo</p>
              </div>

              <div className="company_content">
                <div className="company_logo_selected">
                  <img
                    src={
                      business.business_logo ? business.business_logo : avatar
                    }
                    alt="busniess logo"
                  />
                </div>
                <div className="newbusiness_image_outline">
                  <div className="newbusiness_image_outline_innerd">
                    <div className="image_icon_cont">
                      <BiImport className="image_icon" />
                    </div>

                    <p className="click_to_upload_ptag">
                      <span onClick={handleClick}>Click to Upload</span> image
                      or drag and drop
                    </p>
                    <p className="image_type">
                      SVG,PNG,JPG, or GIF (max 800 x 400px)
                    </p>
                    <input
                      type="file"
                      ref={(ref) => (imageInputRef.current = ref)}
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                      multiple
                    />
                  </div>
                </div>
              </div>
            </div>
            {isPending ? (
              <section className="loader" style={{ height: "20px" }}>
                <div className="spinner-border" role="status"></div>
              </section>
            ) : (
              <button
                className="submit-password"
                onClick={handleUpdateBusiness}
              >
                Update Profile
              </button>
            )}
          </div>
        )
      )}
    </>
  );
}
