import "../../styles/comoponents_pages/login.scss";
import AuthWrapper from "./AuthWrapper";
import { useNavigate } from "react-router-dom";
import Input from "../../components/Input";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useLoginService } from "../../services/auth";
import { STORAGE_KEYS } from "../../constants/constants";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/slices/AppLayout";

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [login, setLogin] = useState({
    email: "",
    password: "",
  });

  const loginUser = useMutation({
    mutationFn: useLoginService,
    onSuccess: (data) => {
      localStorage.setItem(STORAGE_KEYS.USER, JSON.stringify(data.data.user));
      localStorage.setItem(
        STORAGE_KEYS.AUTH_TOKEN,
        JSON.stringify(data.data.auth_token)
      );
      dispatch(setUser(data.data.user));
      navigate("/");
    },
  });

  const handleLogin = async (e) => {
    e.preventDefault();
    await loginUser.mutateAsync(login);
  };

  return (
    <AuthWrapper
      bottomContent={
        <div className="signup pointer">
          No account yet ?
          <span
            className="primary-color"
            onClick={() => navigate("/auth/signup")}
          >
            Create account
          </span>
        </div>
      }
    >
      <div>
        <center>
          <header className="header-text">
            <h2>Welcome Back</h2>
          </header>
        </center>
        <form action="" className="login-form">
          <Input
            label="Email address"
            type="email"
            input={login.email}
            setInput={(input) => setLogin({ ...login, email: input })}
          />

          <Input
            label="Password"
            type="password"
            input={login.password}
            setInput={(input) => setLogin({ ...login, password: input })}
          />

          <div className="recovery-section">
            <div className="forgot-section">Forgot password</div>
          </div>

          <div className="button-section">
            <button className="continue" onClick={(e) => handleLogin(e)}>
              Continue
            </button>
          </div>
        </form>
      </div>
    </AuthWrapper>
  );
}
