// import { useState } from "react";
import { useEffect, useState } from "react";
import {
  useChangePassword,
  useEditProfileService,
  useGetProfile,
} from "../../../services/auth";
import "../../../styles/comoponents_pages/profile.scss";
import { BsPencil } from "react-icons/bs";
import UploadAvatar from "./Uploader/UploadAvatar";
import InputError from "../../InputError";
import toast from "react-hot-toast";
import { useMutation } from "@tanstack/react-query";

function validatePassword(password) {
  const regex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&_])[A-Za-z\d@$!%*?&_]{8,}$/;
  return regex.test(password);
}

const notify = (msg) => toast(msg);

const profileUpdated = () => toast("User information updated successfully");

export default function SettingsProfile() {
  const [user, setUser] = useState(null);
  const { data } = useGetProfile();
  const [password, setPassword] = useState({
    old: "",
    new: "",
    confirm: "",
  });

  const changePassword = useChangePassword();
  const { isPending } = changePassword;

  const [upload, setUpload] = useState(false);

  const editProfile = useMutation({
    mutationFn: useEditProfileService,
    onSuccess: () => {
      profileUpdated();
    },
  });

  const handleChangePassword = () => {
    if (validatePassword(password.new) && password.new === password.confirm) {
      changePassword.mutate(password, {
        onSuccess: () => {
          notify("Password Changed successfully");
        },
        onError: (err) => {
          notify(err.data.message);
        },
      });
    }
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    const userData = {
      name: user.full_name,
      email: user.email,
      phone: user.phone_number,
    };
    await editProfile.mutateAsync(userData);
  };

  useEffect(() => {
    if (data) {
      setUser(data.user);
    }
  }, [data]);
  return (
    <>
      <div className="profile_container">
        <div className="profile_container_grid">
          <div className="account_profile">
            <div className="account_profile_div1">
              <h3>Account Profile</h3>
              <p>
                You can edit the information <br /> on your profile
              </p>
              <h4>Edit Profile</h4>
            </div>

            <div className="account_profile_div2">
              <div className="account_profile_div2_image">
                <img src={user && user.avatar} alt="" />
              </div>
              <span
                className="edit_photo"
                onClick={() => {
                  setUpload(true);
                }}
              >
                <BsPencil /> Edit Photo
              </span>
            </div>
            {upload && <UploadAvatar open={upload} setOpen={setUpload} />}
          </div>
          <div className="account_profile_inpts">
            <form action="">
              <div className="account_profile_inpts_innerd">
                <label htmlFor="">
                  Full name <br />
                  <input
                    type="text"
                    value={user && user.full_name}
                    onChange={(e) =>
                      setUser((prev) => {
                        return { ...prev, full_name: e.target.value };
                      })
                    }
                  />
                </label>
              </div>

              <div className="account_profile_inpts_innerd2">
                <label htmlFor="">
                  Email Address <br />
                  <input
                    type="text"
                    value={user && user.email}
                    onChange={(e) =>
                      setUser((prev) => {
                        return { ...prev, email: e.target.value };
                      })
                    }
                  />
                </label>
              </div>

              <div className="country_select_container">
                <div className="country_select_container_div1">
                  <span className="country_code">+1</span>
                </div>
                <div className="account_profile_inpts_innerd3">
                  <label htmlFor="">
                    <input
                      type="text"
                      value={user && user.phone_number}
                      placeholder="000 0000 0000"
                      onChange={(e) =>
                        setUser((prev) => {
                          return { ...prev, phone_number: e.target.value };
                        })
                      }
                    />
                  </label>
                </div>
              </div>
              <button className="submit-password" onClick={handleUpdateProfile}>
                Update Profile
              </button>
            </form>
          </div>
        </div>
        <hr className="profile_container_hr" />
        <div className="profile_container_grid2">
          <div className="account_profile_div3">
            <h3>Change Password</h3>
            <p>
              Set up a new password. Login <br /> with this new password{" "}
            </p>
            <h4>Change password</h4>
          </div>
          <div className="account_profile_div4">
            <div className="account_profile_inpts_innerd4">
              <input
                type="text"
                className="account_profile_inpts_innerd4_inpt"
                placeholder="Current Password"
                value={password.old}
                onChange={(e) =>
                  setPassword({ ...password, old: e.target.value })
                }
              />
            </div>

            <div className="account_profile_inpts_innerd5">
              <input
                type="text"
                className="account_profile_inpts_innerd4_inpt"
                placeholder="New Password"
                value={password.new}
                onChange={(e) =>
                  setPassword({ ...password, new: e.target.value })
                }
              />
            </div>
            {password.new && !validatePassword(password.new) && (
              <InputError message="Password must contain at least one special character, alphabet and number" />
            )}

            <div className="account_profile_inpts_innerd5">
              <input
                type="text"
                className="account_profile_inpts_innerd4_inpt"
                placeholder="Confirm Password"
                value={password.confirm}
                onChange={(e) =>
                  setPassword({ ...password, confirm: e.target.value })
                }
              />
            </div>
            {password.new !== password.confirm && (
              <InputError message="Password must be same with new passsword" />
            )}
            {isPending ? (
              <div className="spinner-border" role="status"></div>
            ) : (
              <button
                className="submit-password"
                onClick={handleChangePassword}
              >
                Submit Password
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
