import Head from "../../layout/head/Head";
import { useEffect, useState } from "react";
import DeletePrompt from "../admin/prompt/DeletePrompt";
// import { Table } from "../admin/orders/Table";
import PageHeader from "./../Header/index";
import { useParams } from "react-router-dom";
import { useGetCustomerOrders } from "../../services/customer";
import EmptyCatalogue from "../empty_states/EmptyCatalogue";
import { Table } from "../admin/orders/Table";
import { useInView } from "react-intersection-observer";

const tableheaders = [
  "No",
  "Order ID",
  "Order Date",
  "Phone Number",
  "Location",
  "Order Total",
  "Order Status",
];

export default function SingleCustomerTable({ name }) {
  const { customerId } = useParams();
  const { ref, inView } = useInView();
  const [editCourse, setEditCourse] = useState({
    toggle: false,
    toEdit: "",
  });

  const [deleteCourse, setDeleteCourse] = useState({
    deletePrompt: false,
    lessonToDelete: "",
  });

  const { data, status, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useGetCustomerOrders(customerId);

  function handleDeleteCourse() {
    console.log("course deleted");
  }

  const [list, setList] = useState([]);

  useEffect(() => {
    if (data) {
      console.log(data);
      const orders = data.pages.flatMap((page) => page.data.orders.data);
      setList(orders);
    }
  }, [data]);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  return (
    <>
      <Head title="Subject | Course Manager" />
      <div onClick={() => setEditCourse({ ...editCourse, toEdit: "" })}>
        {status === "pending" ? (
          <div className="spinner-border" role="status"></div>
        ) : list.length ? (
          <div
            className="history-table"
            style={{
              border: "1px",
              borderColor: "#E0DDD9",
              borderStyle: "solid",
              margin: "10px 0",
              padding: "20px",
            }}
          >
            <div className="table_header">
              <PageHeader showOptions={false} title="Order History" />
            </div>
            <Table
              headers={tableheaders}
              link={"/manager/orders/"}
              lists={list}
              userName={name}
              more={
                <>
                  {!isFetchingNextPage && status !== "pending" && (
                    <button
                      style={{ opacity: 0 }}
                      onClick={fetchNextPage}
                      ref={ref}
                    >
                      Load More
                    </button>
                  )}
                </>
              }
              fetchingNextPage={isFetchingNextPage}
            />
          </div>
        ) : (
          <EmptyCatalogue
            title="No Orders yet"
            body="This customer has not made any order(BE error, was reported to BE Engineer)"
          />
        )}
      </div>
      {/* </LayoutWithSidebar> */}

      <div className="modal">
        <DeletePrompt
          showState={deleteCourse.deletePrompt}
          setShowState={() => {
            setDeleteCourse({
              ...deleteCourse.deletePrompt,
              deletePrompt: false,
            });
          }}
          itemToDelete={"Course"}
          handleDelete={() => {
            handleDeleteCourse();
          }}
        />
      </div>
    </>
  );
}
