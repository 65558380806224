import React, { useState } from "react";
import AuthWrapper from "./AuthWrapper";
import "../../styles/comoponents_pages/login.scss";
import Success from "./onboarding/Success";
import { useNavigate } from "react-router-dom";
import Input from "../../components/Input";

function ChangePassword() {
  const navigate = useNavigate();

  const [regPage, setRegPage] = useState(0);

  return (
    <div>
      <AuthWrapper>
        {regPage === 0 && (
          <div className="">
            <center>
              <header className="header-text">
                <h2>Create new password</h2>
                <p>Create your unique password to get into your account</p>
              </header>
            </center>
            <form action="" className="login-form">
              <Input label="Password" type="password" />
              <Input label="Confirm password" type="password" />
            </form>
            <button
              style={{ marginTop: "30px" }}
              onClick={() => setRegPage((prev) => prev + 1)}
            >
              Create password
            </button>
          </div>
        )}
        {regPage === 1 && (
          <Success
            title="Password changed"
            body="You can always make changes to your password at the profile section."
            btnTitle="
            Login"
            handleClick={() => navigate("/auth/login")}
          />
        )}
      </AuthWrapper>
    </div>
  );
}

export default ChangePassword;
