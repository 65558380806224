import Head from "../../layout/head/Head";
// import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import DeletePrompt from "../admin/prompt/DeletePrompt";
import { Table } from "./Table";
import { useGetCustomers } from "../../services/customer";
import EmptyCatalogue from "../empty_states/EmptyCatalogue";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalSearch } from "../../redux/slices/AppLayout";
import { useInView } from "react-intersection-observer";

const tableheaders = [
  "Full Name",
  "Phone Number",
  "Total Orders",
  "Total Amount spent",
];

export default function CustomerTable() {
  const dispatch = useDispatch();
  const { ref, inView } = useInView();
  const [customers, setCustomers] = useState([]);
  const [editCourse, setEditCourse] = useState({
    toggle: false,
    toEdit: "",
  });

  const [deleteCourse, setDeleteCourse] = useState({
    deletePrompt: false,
    lessonToDelete: "",
  });

  const { globalSearch } = useSelector((state) => state.appLayout);

  useEffect(() => {
    dispatch(setGlobalSearch(""));
  }, [dispatch]);

  function handleDeleteCourse() {
    console.log("course deleted");
  }

  const { data, status, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useGetCustomers(globalSearch);

  useEffect(() => {
    if (data) {
      const customers = data.pages.flatMap((page) => page.data.orders.data);
      setCustomers(customers);
    }
  }, [data]);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  return (
    <>
      <Head title="Subject | Course Manager" />

      <div onClick={() => setEditCourse({ ...editCourse, toEdit: "" })}>
        {status === "pending" ? (
          <div className="spinner-container">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : data && customers.length ? (
          <div
            className="history-table"
            style={{
              border: "1px",
              borderColor: "#E0DDD9",
              borderStyle: "solid",
              margin: "10px 0",
              padding: "20px",
            }}
          >
            <Table
              headers={tableheaders}
              // link={":customer/viewcustomer"}
              lists={customers}
              more={
                <>
                  {!isFetchingNextPage && status !== "pending" && (
                    <button
                      style={{ opacity: 0 }}
                      onClick={fetchNextPage}
                      ref={ref}
                    >
                      Load More
                    </button>
                  )}
                </>
              }
              fetchingNextPage={isFetchingNextPage}
            />
          </div>
        ) : (
          <EmptyCatalogue
            title="No Customers yet"
            body="All customers will appear here"
          />
        )}
      </div>

      <div className="modal">
        <DeletePrompt
          showState={deleteCourse.deletePrompt}
          setShowState={() => {
            setDeleteCourse({
              ...deleteCourse.deletePrompt,
              deletePrompt: false,
            });
          }}
          itemToDelete={"Course"}
          handleDelete={() => {
            handleDeleteCourse();
          }}
        />
      </div>
    </>
  );
}
