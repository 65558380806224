import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJs,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from "chart.js";
import "../../styles/chart/questionAnalysis.scss";
import { useDispatch, useSelector } from "react-redux";
import { setChartFilter } from "../../redux/slices/AppLayout";

ChartJs.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend
);

function LineChart({ title, chartData }) {
  const { keys, values } = chartData;
  const dispatch = useDispatch();
  const { chartFilter } = useSelector((state) => state.appLayout);
  const data = {
    labels: keys,
    datasets: [
      {
        // label: "Weekdays",
        data: values,
        borderColor: "#D0965A",
        tension: 0.4,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: true,
        },
      },
      y: {
        grid: {
          display: false,
        },
        beginAtZero: true,
      },
    },
  };
  return (
    <div className="line-chart-container">
      <header>
        <h2>{title}</h2>
        <div className="durations-select">
          <div
            className={`duration ${
              chartFilter === "weekly" ? "chart-selected" : ""
            }`}
            onClick={() => dispatch(setChartFilter("weekly"))}
          >
            Weekly
          </div>
          <div
            className={`duration ${
              chartFilter === "monthly" ? "chart-selected" : ""
            }`}
            onClick={() => dispatch(setChartFilter("monthly"))}
          >
            Monthly
          </div>
        </div>
      </header>
      <Line data={data} options={options}></Line>
    </div>
  );
}

export default LineChart;
