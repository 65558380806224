import React, { useState } from "react";
import AuthWrapper from "./AuthWrapper";
import "../../styles/comoponents_pages/login.scss";
import EnterOtp from "./onboarding/EnterOtp";
import { useNavigate } from "react-router-dom";
import Input from "../../components/Input";
import { useMutation } from "@tanstack/react-query";
import {
  useForgotPasswordService,
  useVerifyForgotOTPService,
} from "../../services/auth";

function ForgotPassword() {
  const navigate = useNavigate();
  const [regPage, setRegPage] = useState(0);
  const [email, setEmail] = useState("");

  const forgotPassword = useMutation({
    mutationFn: useForgotPasswordService,
    onSuccess: (data) => {
      setRegPage((prev) => prev + 1);
    },
  });

  const verifyCode = useMutation({
    mutationFn: useVerifyForgotOTPService,
    onSuccess: () => {
      navigate("/auth/change-password");
    },
  });

  const handleForgotPassword = (e) => {
    e.preventDefault();
    forgotPassword.mutateAsync({
      email,
    });
  };
  return (
    <div>
      <AuthWrapper>
        {regPage === 0 && (
          <div>
            <center>
              <header className="header-text">
                <h2>Forgot Password</h2>
                <p>Enter email address registered with this account</p>
              </header>
            </center>
            <Input
              label="Email address"
              type="email"
              input={email}
              setInput={(input) => setEmail(input)}
            />

            <button
              style={{ marginTop: "30px" }}
              onClick={(e) => handleForgotPassword(e)}
            >
              Send code
            </button>
          </div>
        )}
        {regPage === 1 && (
          <EnterOtp
            onVerify={(otp) => {
              verifyCode.mutateAsync({
                email,
                code: otp,
              });
            }}
          />
        )}
      </AuthWrapper>
    </div>
  );
}

export default ForgotPassword;
