import React from "react";
import Modal from "react-bootstrap/Modal";
import "../../styles/comoponents_pages/modal.scss";

function CenteredPopup(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-container"
    >
      <Modal.Body className="body">
        {props.icon && <div className="icon">{props.icon}</div>}

        <div className="modal-body">{props.children}</div>
      </Modal.Body>
      {props.footer && <div className="modal-footer">{props.footer}</div>}
    </Modal>
  );
}

export default CenteredPopup;
