import "./styles/global.scss";
import "./App.css";
import { RouterProvider } from "react-router-dom";
import { routes } from "./routes";
import { useSelector } from "react-redux";

function App() {
  const { user } = useSelector((state) => state.appLayout);
  return (
    <>
      <RouterProvider router={routes(user)} />
    </>
  );
}

export default App;
