import Head from "../../../layout/head/Head";
import React from "react";
import LayoutWithSidebar from "../../../layout/template/LayoutWithSidebar";
import "../../../styles/comoponents_pages/SingleProduct.scss";
import PageHeader from "../../../components/Header";
import { BsTrash3, BsPencil } from "react-icons/bs";
import { useGetSingleProduct } from "../../../services/products";
import { useParams } from "react-router-dom";
import EditProduct from "./EditProduct";
import DeleteProduct from "./DeleteProduct";

export default function SingleProduct() {
  const [editModal, setEditModal] = React.useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const { productId } = useParams();
  const { data, status } = useGetSingleProduct(productId);

  return (
    <>
      <Head title="Products Details" />
      <LayoutWithSidebar>
        <PageHeader title="Products Details" showOptions={false} />
        {status === "pending" ? (
          <div className="spinner-border" role="status"></div>
        ) : (
          <div className="product_details_container">
            <div className="product_image_Container">
              <img src={data && data.product_images[0].image} alt="product" />
            </div>
            <div className="product_image_description">
              <div className="product_action_container">
                <div className="product_action_container_div1">
                  <h3>{data.name}</h3>
                  <span className="active categories">
                    {data.product_category.name}
                  </span>
                </div>
                <div className="product_action_container_div2">
                  <span className="edit" onClick={() => setEditModal(true)}>
                    <BsPencil /> Edit
                  </span>
                  <span className="delete" onClick={() => setModalShow(true)}>
                    <BsTrash3 /> Delete
                  </span>
                </div>
              </div>
              <p className="product_kind">Iced with foam</p>
              <span className="product_price">${data.price}</span>
              <span className="product_desc">{data.description}</span>
              <div className="product_ingredient_container">
                <div className="product_ingredient_qty">
                  <h3>Size</h3>
                  {data.sizes.map((item) => {
                    return <p>{item}</p>;
                  })}
                </div>
                <div className="product_ingredient_content">
                  <h3>Milk</h3>
                  {data.milk_types.map((item) => {
                    return <p>{item}</p>;
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
        {editModal && (
          <EditProduct
            opendEdit={editModal}
            setOpenEdit={setEditModal}
            productId={productId}
            product={data}
          />
        )}

        <DeleteProduct
          modalShow={modalShow}
          setModalShow={setModalShow}
          productId={productId}
        />
      </LayoutWithSidebar>
    </>
  );
}
