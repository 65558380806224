import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import requestNew from "../app/requestNew";

export const useRegisterService = (data) => {
  const response = requestNew({
    url: `register`,
    method: "POST",
    data: {
      full_name: data.name,
      phone_number: data.phone_number,
      email: data.email,
      password: data.password,
      password_confirmation: data.password,
    },
  });
  return response;
};

// Login
export const useLoginService = (data) => {
  const response = requestNew({
    url: `login`,
    method: "POST",
    data: {
      email: data.email,
      password: data.password,
    },
  });
  return response;
};

// Forgot password
export const useForgotPasswordService = (data) => {
  const response = requestNew({
    url: `password/forgot`,
    method: "POST",
    data: {
      email: data.email,
    },
  });
  return response;
};

export const useVerifyForgotOTPService = (data) => {
  const response = requestNew({
    url: `password/otp/verify`,
    method: "POST",
    data: {
      email: data.email,
      code: data.code,
    },
  });
  return response;
};

export const useGetProfile = () => {
  return useQuery({
    queryKey: ["user-profile"],
    queryFn: async () => {
      const data = await requestNew({
        url: "profile/fetch",
        method: "GET",
      });
      return data.data;
    },
  });
};

// Create product
const changePassword = (data) => {
  const request = requestNew({
    url: "password/change",
    method: "POST",
    data: {
      old_password: data.old,
      new_password: data.new,
      new_password_confirmation: data.confirm,
    },
  });
  return request;
};

export const useChangePassword = () => {
  // const queryClient = useQueryClient();

  return useMutation({
    mutationFn: changePassword,
    onSuccess: () => {
      console.log("Hello world");
    },
  });
};

// Edit Profile
export const useEditProfileService = (data) => {
  const response = requestNew({
    url: "profile/update",
    method: "POST",
    data: {
      email: data.email,
      phone_number: data.phone,
      full_name: data.name,
    },
  });
  return response;
};

export const useGetBusinesssProfile = () => {
  return useQuery({
    queryKey: ["business-profile"],
    queryFn: async () => {
      const data = await requestNew({
        url: "admin/business/profile/fetch",
        method: "GET",
      });
      return data.data;
    },
  });
};

export const useEditBusinessProfileService = (data) => {
  console.log(data);
  const response = requestNew({
    url: "admin/business/profile/update",
    method: "POST",
    data: {
      business_name: data.business_name,
      store_location: data.store_location,
      business_email: data.business_email,
      country: data.country,
      phone_number: data.phone_number,
      business_logo: data.business_logo ? data.business_logo : undefined,
    },
  });
  return response;
};

// Create product
const editBusinessProfile = (data) => {
  const request = requestNew({
    url: "admin/business/profile/update",
    method: "POST",
    data,
  });
  return request;
};

export const useUpdateBusinessProfile = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editBusinessProfile,
    onSuccess: () => {
      queryClient.invalidateQueries(["business-profile"]);
    },
  });
};

export const useGetBusinesssPreferences = () => {
  return useQuery({
    queryKey: ["preferences"],
    queryFn: async () => {
      const data = await requestNew({
        url: "admin/business/preferences/fetch",
        method: "GET",
      });
      return data.data;
    },
    staleTime: 0,
  });
};

export const useEditBusinessPrefferencesService = (data) => {
  const {
    email_notifications,
    sms_notifications,
    personal_sms_notifications,
    payment_methods,
    minimum_order,
  } = data;
  const response = requestNew({
    url: "admin/business/preferences/update",
    method: "POST",
    data: {
      email_notifications,
      sms_notifications,
      personal_sms_notifications,
      payment_methods,
      minimum_order,
    },
  });
  return response;
};

export const useGetPreparatoryTime = () => {
  return useQuery({
    queryKey: ["preparatory-time"],
    queryFn: async () => {
      const data = await requestNew({
        url: "admin/business/preparation-time/fetch",
        method: "GET",
      });
      return data.data;
    },
    staleTime: 0,
  });
};

export const useEditPrepTimeService = (time) => {
  const response = requestNew({
    url: "admin/business/preparation-time/update",
    method: "POST",
    data: {
      preparation_time: time,
    },
  });
  return response;
};

export const useGetBusinesssHours = () => {
  return useQuery({
    queryKey: ["business-hours"],
    queryFn: async () => {
      const data = await requestNew({
        url: "admin/business/hours/fetch",
        method: "GET",
      });
      return data.data;
    },
  });
};

export const useEditBusinessHour = (data) => {
  const response = requestNew({
    url: "admin/business/hours/update",
    method: "POST",
    data: {
      business_hours: data,
    },
  });
  return response;
};
