import "../../styles/comoponents_pages/sidebar.scss";
import logo from "../../assets/icons/RGCoffeeLogo.svg";
import { RxDashboard } from "react-icons/rx";
import { useLocation, useNavigate } from "react-router-dom";
import { FaPeopleGroup } from "react-icons/fa6";
import { MdProductionQuantityLimits } from "react-icons/md";
import { TfiWrite } from "react-icons/tfi";
import OrderIcon from "../../assets/icons/OrderIcon";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

export default function Sidebar() {
  const { showFullSidebar } = useSelector((state) => state.appLayout);

  const [sideBarWidth, setSideBarWidth] = useState("");

  useEffect(() => {
    if (showFullSidebar) {
      setSideBarWidth("20%");
    } else {
      setSideBarWidth("5%");
    }
  }, [showFullSidebar]);

  const sideBarItems = [
    {
      title: "Dashboard",
      onclick: () => {},
      icon: <RxDashboard />,
      active: true,
      link: "/",
    },
    {
      title: "Orders",
      onclick: () => {},
      active: true,
      icon: <OrderIcon dimension={{ width: "20", height: "20" }} />,
      link: "/manager/orders",
    },
    {
      title: "Products",
      onclick: () => {},
      icon: <MdProductionQuantityLimits />,
      active: true,
      link: "/manager/products",
    },
    {
      title: "Customers",
      onclick: () => {},
      active: true,
      icon: <FaPeopleGroup />,
      link: "/manager/customer",
    },
    {
      title: "Reviews",
      onclick: () => {},
      active: true,
      icon: <TfiWrite />,
      link: "/manager/reviews",
    },
    {
      title: "Queries",
      onclick: () => {},
      active: false,
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 8C20.6569 8 22 6.65685 22 5C22 3.34315 20.6569 2 19 2C17.3431 2 16 3.34315 16 5C16 6.65685 17.3431 8 19 8Z"
            stroke="#C6C8D3"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7 13H12"
            stroke="#C6C8D3"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7 17H16"
            stroke="#C6C8D3"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V10"
            stroke="#C6C8D3"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      link: "/manager/queries",
    },
    {
      title: "Settings",
      onclick: () => {},
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.34"
            d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
            stroke="#C6C8D3"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2 12.8804V11.1204C2 10.0804 2.85 9.22043 3.9 9.22043C5.71 9.22043 6.45 7.94042 5.54 6.37042C5.02 5.47042 5.33 4.30042 6.24 3.78042L7.97 2.79042C8.76 2.32042 9.78 2.60042 10.25 3.39042L10.36 3.58042C11.26 5.15042 12.74 5.15042 13.65 3.58042L13.76 3.39042C14.23 2.60042 15.25 2.32042 16.04 2.79042L17.77 3.78042C18.68 4.30042 18.99 5.47042 18.47 6.37042C17.56 7.94042 18.3 9.22043 20.11 9.22043C21.15 9.22043 22.01 10.0704 22.01 11.1204V12.8804C22.01 13.9204 21.16 14.7804 20.11 14.7804C18.3 14.7804 17.56 16.0604 18.47 17.6304C18.99 18.5404 18.68 19.7004 17.77 20.2204L16.04 21.2104C15.25 21.6804 14.23 21.4004 13.76 20.6104L13.65 20.4204C12.75 18.8504 11.27 18.8504 10.36 20.4204L10.25 20.6104C9.78 21.4004 8.76 21.6804 7.97 21.2104L6.24 20.2204C5.33 19.7004 5.02 18.5304 5.54 17.6304C6.45 16.0604 5.71 14.7804 3.9 14.7804C2.85 14.7804 2 13.9204 2 12.8804Z"
            stroke="#C6C8D3"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      active: false,
      link: "/manager/settings",
    },
    {
      title: "Logout",
      onclick: () => {},
      icon: (
        <svg
          width="23"
          height="24"
          viewBox="0 0 23 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.8458 7.3381V6.41154C13.8458 4.39057 12.3245 2.75195 10.4482 2.75195H5.95336C4.07797 2.75195 2.55664 4.39057 2.55664 6.41154V17.4648C2.55664 19.4857 4.07797 21.1244 5.95336 21.1244H10.4574C12.3282 21.1244 13.8458 19.4907 13.8458 17.4757V16.5392"
            stroke="#C6C8D3"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.1098 11.938H9.00781"
            stroke="#C6C8D3"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.4102 9.04297L20.1098 11.9379L17.4102 14.8338"
            stroke="#C6C8D3"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      active: false,
      link: "",
    },
  ];

  const navigate = useNavigate();

  const { pathname } = useLocation();
  const thisPath = pathname.split("/:")[0];

  return (
    <div className="sidebar-container" style={{ width: sideBarWidth }}>
      <div className="logo-section">
        <div className={showFullSidebar ? "logo-container" : "logo-collapsed"}>
          <img src={logo} alt="Stucademy logo" />
        </div>
        {showFullSidebar && (
          <h1 className="sidebar-heading">Righteous Grounds Coffee Roasters</h1>
        )}
      </div>

      <div className="sidebar-items">
        {sideBarItems.map((item, i) => {
          return (
            item.active && (
              <div
                key={i}
                className={
                  thisPath === item.link ||
                  pathname.includes(item.title.toLowerCase())
                    ? "active-sidebar-item"
                    : "item"
                }
                style={
                  item.title.toLowerCase() === "logout"
                    ? { marginTop: "100px" }
                    : null
                }
                onClick={() => {
                  navigate(`${item.link}`);
                }}
              >
                <div
                  className={thisPath === item.link ? "active-icon" : "icon"}
                >
                  {item.icon}
                </div>
                {showFullSidebar && <div className="text">{item.title}</div>}
              </div>
            )
          );
        })}
      </div>
    </div>
  );
}
