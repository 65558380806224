import { useQuery } from "@tanstack/react-query";
import requestNew from "../app/requestNew";

export const useGetCardSummary = (summaryCardsFilter) => {
  const filterParam =
    summaryCardsFilter && summaryCardsFilter.type === "date"
      ? {
          start: summaryCardsFilter.start_date,
          end: summaryCardsFilter.end_date,
        }
      : summaryCardsFilter
      ? { filter: summaryCardsFilter }
      : summaryCardsFilter;
  return useQuery({
    queryKey: ["card-summary", summaryCardsFilter],
    queryFn: async () => {
      const data = await requestNew({
        url: "admin/dashboard/summary/cards",
        method: "GET",
        params: filterParam,
      });
      return data;
    },
  });
};

export const useGetCharts = (dataType) => {
  return useQuery({
    queryKey: ["charts", dataType],
    queryFn: async () => {
      const data = await requestNew({
        url: "admin/dashboard/summary/charts",
        method: "GET",
        params: {
          filter: dataType,
        },
      });
      return data;
    },
  });
};
