import SettingsProfile from "../components/admin/settings/Profile";
import BusinessProfile from "../components/admin/settings/BusinessProfile";
import PreferenceSettings from "../components/admin/settings/PreferenceSettings";
import StoreSettings from "../components/admin/settings/StoreSettings";
import LayoutWithSidebar from "./../layout/template/LayoutWithSidebar";
import PageHeader from "./../components/Header/index";
import { useDispatch, useSelector } from "react-redux";
import { setSettingPage } from "../redux/slices/AppLayout";

export default function Settings() {
  const dispatch = useDispatch();
  const { settingPage } = useSelector((state) => state.appLayout);

  const renderComponent = () => {
    switch (settingPage) {
      case "profile":
        return <SettingsProfile />;
      case "business":
        return <BusinessProfile />;
      case "preference":
        return <PreferenceSettings />;
      case "store":
        return <StoreSettings />;
      default:
        return null;
    }
  };

  return (
    <LayoutWithSidebar>
      <PageHeader title="Settings" showOptions={false} />
      <div className="settings_type_cont">
        <span
          className={`settings_type_cont_btn ${
            settingPage === "profile" ? "active_btn" : ""
          }`}
          onClick={() => dispatch(setSettingPage("profile"))}
        >
          Profile
        </span>
        <span
          className={`settings_type_cont_btn ${
            settingPage === "business" ? "active_btn" : ""
          }`}
          onClick={() => dispatch(setSettingPage("business"))}
        >
          Business Profile
        </span>
        <span
          className={`settings_type_cont_btn ${
            settingPage === "preference" ? "active_btn" : ""
          }`}
          onClick={() => dispatch(setSettingPage("preference"))}
        >
          Preferences
        </span>
        <span
          className={`settings_type_cont_btn ${
            settingPage === "store" ? "active_btn" : ""
          }`}
          onClick={() => dispatch(setSettingPage("store"))}
        >
          Store
        </span>
      </div>

      {renderComponent()}
    </LayoutWithSidebar>
  );
}
