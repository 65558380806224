import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import requestNew from "../app/requestNew";

export const useGetOrders = (filter, search) => {
  const filterParam =
    filter && filter.type === "date"
      ? { start_date: filter.start_date, end_date: filter.end_date }
      : filter
      ? { status: filter }
      : filter;

  const getOrders = ({ pageParam }) => {
    const data = requestNew({
      url: "admin/order/fetch",
      method: "GET",
      params: {
        ...filterParam,
        search,
        page: pageParam || 1,
      },
    });
    return data;
  };
  return useInfiniteQuery({
    refetchOnMount: true,
    staleTime: 0,
    queryKey: ["orders", filter, search],
    queryFn: getOrders,
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      const { data } = lastPage;
      if (!lastPage.data || lastPage.data.length < data.per_page) {
        return false;
      }
      return allPages.length + 1;
    },
  });
};

// Get Category
export const useGetOrderDetails = (id) => {
  return useQuery({
    queryKey: ["order-details", id],
    queryFn: async () => {
      const data = await requestNew({
        url: `admin/order/${id}/show`,
        method: "GET",
      });
      return data.data;
    },
    enabled: !!id,
  });
};

//
// Create product
const editOrderStatus = (data) => {
  const request = requestNew({
    url: `admin/order/${data.id}/update`,
    method: "PATCH",
    data: {
      delivery_status: data.delivery_status,
    },
  });
  return request;
};

export const useEditOrderStatus = (filter) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editOrderStatus,
    onSuccess: () => {
      queryClient.invalidateQueries(["orders"]);
    },
    onError: () => {
      queryClient.invalidateQueries(["orders"]);
    },
  });
};
