import { BiFilter } from "react-icons/bi";
import coverImg2 from "../../../assets/images/courses/biology.webp";

export default function AllNotifications() {
  return (
    <div className="all-notifications-container">
      <div className="filter-section">
        <header>
          {" "}
          <BiFilter className="icon" /> Filters
        </header>
        <button className="clear-btn">Clear all Filters</button>

        <div className="filter-item">
          <label htmlFor="queries">Queries</label>
          <input type="checkbox" id="queries" />
        </div>
        <div className="filter-item">
          <label htmlFor="system-settings">System settings</label>
          <input type="checkbox" id="system-settings" />
        </div>
        <div className="filter-item">
          <label htmlFor="new-user">New User</label>
          <input type="checkbox" id="new-user" />
        </div>
        <div className="filter-item">
          <label htmlFor="system-notification">System Notifications</label>
          <input type="checkbox" id="system-notification" />
        </div>
      </div>

      <div className="notifications-list">

        <div className="dated-items">
          <div className="date underlay-text">Today</div>
          <div className="item">
            <div className="d-flex gap-2">
              <div className="initials">JD</div>
              <div className="text">
                <h6>John Doe just sent a report</h6>
                <div className="underlay-text">2022 Oc 24, 3:21pm</div>
              </div>
            </div>
            <div className="icon">
              <img src={coverImg2} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="d-flex gap-2">
              <div className="initials">JD</div>
              <div className="text">
                <h6>John Doe just sent a report</h6>
                <div className="underlay-text">2022 Oc 24, 3:21pm</div>
              </div>
            </div>
            <div className="icon">
              <img src={coverImg2} alt="" />
            </div>
          </div>
        </div>
        <div className="dated-items">
          <div className="date underlay-text">Yesterday</div>
          <div className="item">
            <div className="d-flex gap-2">
              <div className="initials">JD</div>
              <div className="text">
                <h6>John Doe just sent a report</h6>
                <div className="underlay-text">2022 Oc 24, 3:21pm</div>
              </div>
            </div>
            <div className="icon">
              <img src={coverImg2} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="d-flex gap-2">
              <div className="initials">JD</div>
              <div className="text">
                <h6>John Doe just sent a report</h6>
                <div className="underlay-text">2022 Oc 24, 3:21pm</div>
              </div>
            </div>
            <div className="icon">
              <img src={coverImg2} alt="" />
            </div>
          </div>
        </div>
        <div className="dated-items">
          <div className="date underlay-text">25/10/2023</div>
          <div className="item">
            <div className="d-flex gap-2">
              <div className="initials">JD</div>
              <div className="text">
                <h6>John Doe just sent a report</h6>
                <div className="underlay-text">2022 Oc 24, 3:21pm</div>
              </div>
            </div>
            <div className="icon">
              <img src={coverImg2} alt="" />
            </div>
          </div>

          <div className="item">
            <div className="d-flex gap-2">
              <div className="initials">JD</div>
              <div className="text">
                <h6>John Doe just sent a report</h6>
                <div className="underlay-text">2022 Oc 24, 3:21pm</div>
              </div>
            </div>
            <div className="icon">
              <img src={coverImg2} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
