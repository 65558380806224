import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import requestNew from "../app/requestNew";
import { createMilkTypeObject, createSizesObject } from "../helpers/helpers";

// Create product
const createProduct = (data) => {
  const request = requestNew({
    url: "admin/product/store",
    method: "POST",
    data,
  });
  return request;
};

export const useCreateProduct = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createProduct,
    onSuccess: () => {
      queryClient.invalidateQueries(["products"]);
    },
  });
};

// Update Product
const updateProduct = (product) => {
  const { name, description, milk_types, product_category, sizes, id } =
    product;
  const sizeObj = createSizesObject(sizes);
  const milkTypesObj = createMilkTypeObject(milk_types);
  const request = requestNew({
    url: `admin/product/${id}/update`,
    method: "PATCH",
    data: {
      name,
      description,
      product_category,
      ...sizeObj,
      ...milkTypesObj,
    },
  });
  return request;
};

export const useUpdateProduct = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateProduct,
    onSuccess: () => {
      queryClient.invalidateQueries(["products"]);
    },
  });
};

// Delete Product
const deleteProduct = (ids) => {
  const request = requestNew({
    url: "admin/product/delete",
    method: "POST",
    data: {
      ids,
    },
  });
  return request;
};

export const useDeleteProduct = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteProduct,
    onSuccess: () => {
      queryClient.invalidateQueries(["products"]);
    },
  });
};

// Create Category
const createCategory = (name) => {
  const request = requestNew({
    url: "admin/product/category/store",
    method: "POST",
    data: {
      name,
    },
  });
  return request;
};

export const useCreateCategory = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createCategory,
    onSuccess: () => {
      queryClient.invalidateQueries(["category"]);
    },
  });
};

// Update Category
const updateCategory = (id) => {
  const request = requestNew({
    url: `admin/product/category/${id}/update`,
    method: "PATCH",
  });
  return request;
};

export const useUpdateCategory = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateCategory,
    onSuccess: () => {
      queryClient.invalidateQueries(["category"]);
    },
  });
};

// Delete Category
const deleteCategory = (id) => {
  const request = requestNew({
    url: `product/category/${id}/delete`,
    method: "DELETE",
  });
  return request;
};

export const useDeleteCategory = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteCategory,
    onSuccess: () => {
      queryClient.invalidateQueries(["category"]);
    },
  });
};

// Get Category
export const useGetCategory = () => {
  return useQuery({
    queryKey: ["category"],
    queryFn: async () => {
      const data = await requestNew({
        url: "admin/product/category/fetch",
        method: "GET",
      });
      return data.data;
    },
  });
};

export const useFetchProducts = (data) => {
  const { catId, search } = data;
  const useGetProduct = ({ pageParam }) => {
    const data = requestNew({
      url: "products/fetch",
      method: "GET",
      params: {
        category_id: catId,
        search: search,
        page: pageParam || 1,
      },
    });
    return data;
  };
  return useInfiniteQuery({
    queryKey: ["product", catId, search],
    queryFn: useGetProduct,
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      const { data } = lastPage;
      if (!lastPage.data || lastPage.data.length < data.per_page) {
        return false;
      }
      return allPages.length + 1;
    },
  });
};

// Get product details
export const useGetSingleProduct = (id) => {
  return useQuery({
    queryKey: ["single-product", id],
    queryFn: async () => {
      if (!id) {
        return null;
      }
      const data = await requestNew({
        url: `product/${id}/show`,
        method: "GET",
      });
      return data.data;
    },
    enabled: !!id,
  });
};
