import Head from "../../../layout/head/Head";
import LayoutWithSidebar from "../../../layout/template/LayoutWithSidebar";
import "../../../styles/comoponents_pages/products.scss";
import ProductsCatalogue from "./ProductsCatalogue";
import PageHeader from "../../../components/Header";
import ProductHeading from "./ProductHeading";
import { useState } from "react";

export default function Products() {
  const [searchProduct, setSearchProduct] = useState("");
  return (
    <>
      <Head title="Products Manager" />

      <LayoutWithSidebar>
        <div className="product-manager-container">
          <PageHeader title="Products" showOptions={false} />

          <ProductHeading search={searchProduct} setSearch={setSearchProduct} />

          <ProductsCatalogue search={searchProduct} />
        </div>
      </LayoutWithSidebar>
    </>
  );
}
