import React, { useState } from "react";
import CenteredPopup from "../../../components/modal/Modal";
import { AiFillEdit } from "react-icons/ai";
// import Inext from "../../../assets/images/CoffeeCup.svg";
import SelectCategory from "./SelectCategory";
import { useUpdateProduct } from "../../../services/products";

function EditProduct({ opendEdit, setOpenEdit, product }) {
  const { mutate: editProduct } = useUpdateProduct();
  const {
    name,
    price,
    description,
    milk_types,
    product_category,
    // product_images,
    sizes,
  } = product;

  const [editProdoct, setEditProduct] = useState({
    name,
    price,
    description,
    milk_types,
    product_category,
    sizes: sizes.map((i) => i.split("---")),
  });

  const selectCat = (cat) => {
    setEditProduct((prev) => {
      return {
        ...prev,
        product_category: cat,
      };
    });
  };

  const handleSizeChange = (e, i, type) => {
    if (type === "size") {
      const newSize = editProdoct.sizes.map((size, index) => {
        if (index === i) {
          const newSize = [e.target.value, size[1]];
          return newSize;
        }
        return size;
      });
      setEditProduct((prev) => {
        return { ...prev, sizes: newSize };
      });
      console.log(newSize);
    } else {
      const newSize = editProdoct.sizes.map((size, index) => {
        if (index === i) {
          const newSize = [size[0], e.target.value];
          return newSize;
        }
        return size;
      });
      setEditProduct((prev) => {
        return { ...prev, sizes: newSize };
      });
    }
  };

  const handleeditProduct = () => {
    const data = {
      name: editProdoct.name,
      description: editProdoct.description,
      price: editProdoct.price,
      product_category: editProdoct.category,
      sizes: editProdoct.sizes.map((item) => item.join("---")),
      milk_types: milk_types,
      id: product.id,
    };
    // console.log(product.id);
    editProduct(data);
  };

  return (
    <CenteredPopup
      show={opendEdit}
      icon={<AiFillEdit />}
      onHide={() => setOpenEdit("")}
      children={
        // status === "loading" ? (
        //   <div className="spinner-border" role="status"></div>
        // ) : (
        <div className="edit-product-container">
          {/* <div className="images-container">
            {editProdoct.product_images.map((image) => {
              return (
                <div className="edit-image" key={image.id}>
                  <img src={image.image} alt="product" />
                  <div className="replace-btn">Replace</div>
                </div>
              );
            })}
          </div> */}
          <div className="edit-name">
            <label htmlFor="name">Name</label>
            <br />
            <input
              type="text"
              placeholder="Name"
              className="name-input"
              value={editProdoct.name}
              onChange={(e) => {
                setEditProduct((prev) => {
                  return { ...prev, name: e.target.value };
                });
              }}
            />
          </div>
          <div className="edit-name">
            <label htmlFor="name">Price</label>
            <br />
            <input
              type="text"
              placeholder="Name"
              className="name-input"
              value={editProdoct.price}
              onChange={(e) => {
                setEditProduct((prev) => {
                  return { ...prev, price: e.target.value };
                });
              }}
            />
          </div>
          <label htmlFor="name">Category</label>
          <br />
          <SelectCategory
            handleCat={selectCat}
            cat={editProdoct.product_category}
          />
          <div className="edit-name">
            <label htmlFor="name">Description</label>
            <br />
            <textarea
              name="description"
              value={editProdoct.name}
              onChange={(e) => {
                setEditProduct((prev) => {
                  return { ...prev, description: e.target.value };
                });
              }}
            ></textarea>
          </div>
          <label htmlFor="name">Size</label>
          <br />
          {editProdoct.sizes.map((size, index) => {
            return (
              <div className="product_inpts_inner_container" key={index}>
                <input
                  type="text"
                  name="product_size"
                  placeholder="size,name e.g; small"
                  className="product_name_inpt3"
                  value={size[0]}
                  onChange={(event) => handleSizeChange(event, index, "size")}
                  required
                />
                <input
                  type="number"
                  name="product_quantity"
                  className="product_name_inpt3"
                  placeholder="Quantity"
                  value={size[1] ?? ""}
                  onChange={(event) =>
                    handleSizeChange(event, index, "quantity")
                  }
                  required
                />
              </div>
            );
          })}

          <label htmlFor="name">Milk Types</label>
          <br />
          {/* <div className="milk_types">
            <input type="text" />
          </div> */}

          <div className="milk_types">
            {editProdoct.milk_types.map((type) => {
              return <input type="text" placeholder="Input 1" value={type} />;
            })}
          </div>

          <span className="submit-btn" onClick={handleeditProduct}>
            Save Product
          </span>
        </div>
        // )
      }
    />
  );
}

export default EditProduct;
