import "../../../styles/comoponents_pages/AddProduct.scss";
import LayoutWithSidebar from "../../../layout/template/LayoutWithSidebar";
import PageHeader from "../../../components/Header";
import { BiImport } from "react-icons/bi";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import CenteredPopup from "../../../components/modal/Modal";
import {
  useCreateCategory,
  useCreateProduct,
} from "../../../services/products";
import { useGetCategory } from "../../../services/products";
import ProductConfirm from "./ProductConfirm";
import InputError from "../../../components/InputError";

export default function AddProduct() {
  const imageInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageURLs, setImageURLs] = useState([]);
  const [sizeInput, setSizeInput] = useState([
    { id: 1, size: "", quantity: "" },
  ]);
  const [isCompleteData, setIsCompleteData] = useState(false);
  const [milkInput, setMilkInput] = useState([{ id: 1, value: "" }]);
  const [value, setValue] = useState({
    name: "",
    category: "",
    price: "",
    description: "",
  });
  const [productCategory, setCategory] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [productProcessing, setProductProcessing] = useState(false);

  const handleChange = (e, name) => {
    if (e.target.name === "add_category") {
      return setShowModal(!showModal);
    }
    if (name === undefined) {
      return setValue({ ...value, [e.target.name]: e.target.value });
    }
    const arr = value.milk_type ? [value.milk_type] : [];
    setValue((prev) => ({
      ...prev,
      [e.target.name]: [...arr, e.target.value],
    }));
  };

  const handleClick = () => {
    imageInputRef.current.click();
  };

  const handleAddSizeOpt = () =>
    setSizeInput((prevInputs) => [
      ...prevInputs,
      { id: (prevInputs.at(-1)?.id || 1) + 1, size: "", quantity: "" },
    ]);

  const handleAddMilkOpt = () =>
    setMilkInput((prevInputs) => [
      ...prevInputs,
      {
        id: (prevInputs.at(-1)?.id || 1) + 1,
        value: "",
      },
    ]);

  const handleFileChange = (e) => {
    const file = e.target.files;
    if (file) {
      setSelectedImage([...file]);
    }
    const files = Array.from(e.target.files);
    const urls = files.map((file) => URL.createObjectURL(file));
    setImageURLs(urls);

    // Revoke object URLs when the component is unmounted
    return () => {
      urls.forEach((url) => URL.revokeObjectURL(url));
    };
  };

  const RemoveImage = () => {
    setSelectedImage(null);
  };

  const replaceImage = () => {
    handleClick();
  };

  const handleSizeChange = (e, id, type) => {
    const { value: eventValue } = e.target;
    if (type === "milk") {
      setMilkInput((prevInputs) => {
        const newInput = prevInputs.map((i) => {
          if (i.id === id) {
            i.value = eventValue;
          }
          return i;
        });
        return newInput;
      });
    } else {
      setSizeInput((prevInputs) => {
        const newInput = prevInputs.map((i) => {
          if (i.id === id) {
            if (type === "size") {
              i.size = eventValue;
            } else {
              i.quantity = eventValue;
            }
          }
          return i;
        });
        return newInput;
      });
    }
  };

  const { mutate: createCategory } = useCreateCategory();
  const createProduct = useCreateProduct();
  const { status } = createProduct;
  const { data } = useGetCategory();

  const handleCreateCategory = () => {
    const { category_name } = { ...value };

    if (category_name) {
      createCategory(category_name);
    }
  };

  const handleCreateProduct = () => {
    const { name, description, price, category } = value;
    const milk_types = milkInput.map(
      (milkType) => milkType.value[milkType.id - 1]
    );
    const sizes = sizeInput.map((item) => `${item.size}---${item.quantity}`);
    if (name && description && price && category && milk_types && sizeInput) {
      setProductProcessing(true);
      const formData = new FormData();
      formData.append("name", name);
      formData.append("description", description);
      formData.append("price", price);
      formData.append("product_category_id", category);
      sizes.forEach((item, index) => {
        formData.append(`sizes[${index}]`, item);
      });
      milk_types.forEach((item, index) => {
        formData.append(`milk_types[${index}]`, item);
      });
      selectedImage.forEach((item, index) => {
        formData.append(`images[${index}]`, item);
      });
      if (isCompleteData) {
        createProduct.mutate(formData);
      }
    }
  };

  useEffect(() => {
    if (data) {
      const { data: categoryData } = data;
      setCategory(categoryData);
      setShowModal(false);
    }
  }, [data]);

  useEffect(() => {
    const canSubmit =
      value.name && value.description && value.price && selectedImage;
    const isSize = sizeInput[0].size && sizeInput[0].quantity;
    const isMilk = milkInput[0].value;
    if (canSubmit && isSize && isMilk) {
      setIsCompleteData(true);
    } else {
      setIsCompleteData(false);
    }
  }, [value, sizeInput, milkInput, selectedImage]);

  return (
    <>
      <LayoutWithSidebar>
        <PageHeader title="Add Product" showOptions={false} />
        <div className="newproduct_container">
          <div className="newproduct_form_container">
            <div className="newproduct_image">
              <h3>Product Image</h3>
              <div className="newproduct_image_outline">
                {selectedImage && selectedImage.length ? (
                  <div className="selected_image_container">
                    <img src={imageURLs[0] ?? ""} alt="" />
                    <div className="image_action_button">
                      <button onClick={RemoveImage}>Remove</button>
                      <button onClick={replaceImage}>Replace</button>
                    </div>
                  </div>
                ) : (
                  <div className="newproduct_image_outline_innerd">
                    <BiImport className="import_icon" />
                    <p>
                      <span onClick={handleClick}>Click to Upload</span> image
                      or drag and drop
                    </p>
                    <input
                      type="file"
                      ref={(ref) => (imageInputRef.current = ref)}
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                      multiple
                    />
                  </div>
                )}
                <div></div>
              </div>
            </div>
            <div className="newproduct_form">
              <h3>Product Info</h3>
              <form action="">
                <input
                  type="text"
                  name="name"
                  onChange={(e) =>
                    setValue((prev) => ({ ...prev, name: e.target.value }))
                  }
                  placeholder="Product name"
                  className="product_name_inpt"
                  required
                />
                {!value.name && <InputError message="Name is required" />}

                <div className="size-header">
                  <div className="add_size category">
                    <h2>Add Category</h2>
                  </div>
                  <div
                    className="add_another"
                    onClick={() => setShowModal(!showModal)}
                  >
                    <h2 className="text_primary"> + Add New Category </h2>
                  </div>
                </div>
                <select
                  name="category"
                  id="category"
                  onChange={(e) =>
                    setValue((prev) => ({ ...prev, category: e.target.value }))
                  }
                  className="product_name_select"
                >
                  <option value="" className="product_name_select_option">
                    Select a category
                  </option>
                  {productCategory.length > 0 ? (
                    productCategory.map((category) => (
                      <option
                        key={category.id}
                        value={category.id}
                        className="product_name_select_option"
                      >
                        {category.name}
                      </option>
                    ))
                  ) : (
                    <option
                      value="add_category"
                      className="product_name_select_option"
                    >
                      {value.category_name ?? "Add category"}
                    </option>
                  )}
                </select>
                {!value.category && (
                  <InputError message="Category is required" />
                )}
                <CenteredPopup
                  show={showModal}
                  children={
                    <>
                      <h1>Create New Category</h1>
                      <input
                        type="text"
                        name="category_name"
                        onChange={handleChange}
                        placeholder="Category name"
                        className="product_name_inpt"
                      />
                    </>
                  }
                  footer={
                    <>
                      <span
                        className="footer-btn cancel-btn"
                        onClick={() => setShowModal(false)}
                      >
                        Cancel
                      </span>
                      <span
                        onClick={handleCreateCategory}
                        className="footer-btn action-btn category"
                      >
                        Add category
                      </span>
                    </>
                  }
                />
                <input
                  type="number"
                  name="price"
                  onChange={handleChange}
                  placeholder="Product Price"
                  className="product_name_inpt2"
                />
                {!value.price && <InputError message="Price is required" />}
                <textarea
                  name="description"
                  onChange={handleChange}
                  className="product_name_textarea"
                  id=""
                  placeholder="Product description"
                  cols="30"
                  rows="10"
                ></textarea>
                {!value.description && (
                  <InputError message="Description is required" />
                )}
                <div className="size-header">
                  <div className="add_size">
                    <h2>Add Size</h2>
                  </div>
                  <div className="add_another" onClick={handleAddSizeOpt}>
                    <h2 className="text_primary"> + Add Another </h2>
                  </div>
                </div>
                {sizeInput.map((item, index) => {
                  return (
                    <div key={index} className="product_inpts_inner_container">
                      <input
                        type="text"
                        name="product_size"
                        placeholder="size,name e.g; small"
                        className="product_name_inpt3"
                        value={item.size}
                        onChange={(event) =>
                          handleSizeChange(event, item.id, "size")
                        }
                        required
                      />
                      <input
                        type="number"
                        name="product_quantity"
                        className="product_name_inpt3"
                        placeholder="Quantity"
                        value={item.quantity}
                        onChange={(event) =>
                          handleSizeChange(event, item.id, "quantity")
                        }
                        required
                      />
                    </div>
                  );
                })}

                {sizeInput.length === 1 &&
                (!sizeInput[0].size || !sizeInput[0].quantity) ? (
                  <InputError message="Size name and quanity are required" />
                ) : null}

                <div className="size-header">
                  <div className="add_size">
                    <h2>Milk Type</h2>
                  </div>
                  <div className="add_another" onClick={handleAddMilkOpt}>
                    <h2 className="text_primary"> + Add Another </h2>
                  </div>
                </div>

                <div className="milk-types-container">
                  {milkInput.map((data, index) => (
                    <input
                      onChange={(e) => handleSizeChange(e, data.id, "milk")}
                      name="milk_type"
                      key={index}
                      value={data.value}
                      id={data.id}
                      type="text"
                      className="product_name_inpt3"
                      required
                    />
                  ))}
                </div>
                {milkInput.every((i) => !i.value) ? (
                  <InputError message="Milk type is required" />
                ) : null}
              </form>
            </div>
          </div>
          <div className="newproduct_content">
            <div className="newproduct_details">
              <h3>Product Image</h3>
              <div className="newproduct_details_content">
                <div className="newproduct_details_content_innerd1">
                  {imageURLs.length ? (
                    <img src={imageURLs[0] ?? ""} alt="" />
                  ) : (
                    "Image Preview"
                  )}

                  <div>
                    <h3>{value.name ?? ""}</h3>
                    {/* <p className="newproduct_details_content_type">
                      {value.description ? value.description : "Description"}
                    </p> */}
                  </div>
                </div>
                <span>${value.price ?? "0.00"}</span>
              </div>
              <h3 className="newproduct_content_desc_title">Description</h3>
              <p className="newproduct_content_desc">
                {value.description ?? ""}{" "}
              </p>
              <div className="newproduct_details_content_size">
                <h3>
                  Sizes
                  {/* <span className="size_ptag"> Select 1</span> */}
                </h3>
                <div className="newproduct_size_types_cont">
                  {sizeInput.map((size) => (
                    <span className="newproduct_size_types1">
                      {size.size} - {size.quantity}
                    </span>
                  )) ?? ""}
                </div>
              </div>
              <div className="newproduct_details_content_size2">
                <h3>Milk </h3>
                <div className="newproduct_size_types_cont">
                  {milkInput.length > 0
                    ? milkInput.map((milk) => {
                        return (
                          <span className="newproduct_size_types2">
                            {milk.value ? milk.value : "-"}
                          </span>
                        );
                      })
                    : ""}
                </div>
              </div>
            </div>
            <div className="action_btn_container">
              <div>
                <span className="action_button">Cancel</span>
              </div>
              <div onClick={handleCreateProduct}>
                <span
                  className="action_button2"
                  style={{ opacity: !isCompleteData && "0.5" }}
                >
                  Save Product
                </span>
              </div>
            </div>
          </div>
          <ProductConfirm
            status={status}
            modalShow={productProcessing}
            setModalShow={setProductProcessing}
          />
        </div>
      </LayoutWithSidebar>
    </>
  );
}
