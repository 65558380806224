import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

export default function EditOrderStatus({
  anchorEl,
  setAnchorEl,
  handleSelect,
}) {
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const statuses = [
    {
      id: "processing",
      title: "Processing",
      value: "processing",
    },
    {
      id: "in-transit",
      title: "In-transit",
      value: "in-transit",
    },
    {
      id: "delivered",
      title: "Delivered",
      value: "delivered",
    },
  ];

  const handleStatus = (orderStatus) => {
    handleSelect(orderStatus);
    setAnchorEl(null);
  };

  return (
    <div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {statuses.map((orderStatus) => {
          return (
            <MenuItem
              onClick={() => handleStatus(orderStatus)}
              key={orderStatus.id}
            >
              {orderStatus.title}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
