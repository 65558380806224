import React from "react";

function OrderIcon({ color, dimension }) {
  return (
    <svg
      width={dimension.width ?? "20"}
      height={dimension.height ?? "20"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.25 6.39192V5.58358C6.25 3.70858 7.75833 1.86692 9.63333 1.69192C11.8667 1.47525 13.75 3.23358 13.75 5.42525V6.57525"
        stroke={color ?? "#5A524A"}
        strokeWidth="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.49998 18.3327H12.5C15.85 18.3327 16.45 16.991 16.625 15.3577L17.25 10.3577C17.475 8.32435 16.8916 6.66602 13.3333 6.66602H6.66664C3.10831 6.66602 2.52498 8.32435 2.74998 10.3577L3.37498 15.3577C3.54998 16.991 4.14998 18.3327 7.49998 18.3327Z"
        stroke={color ?? "#5A524A"}
        strokeWidth="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.9129 10.0007H12.9204"
        stroke={color ?? "#5A524A"}
        strokeWidth="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.07878 10.0007H7.08626"
        stroke={color ?? "#5A524A"}
        strokeWidth="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default OrderIcon;
