import { Menu } from "@mui/material";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
// import "../../../src/styles/Calendar.css";

function MyCalendar({ anchorEl, setAnchorEl, handleDate, value }) {
  // const [value, setValue] = useState([new Date(), new Date()]);
  // const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <div>
      {/* <div
        className="page-option-card-active"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <h3>Date</h3>
      </div> */}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        className="settings-modal"
        PaperProps={{
          style: {
            width: 300,
          },
        }}
      >
        <Calendar onChange={handleDate} value={value} selectRange={true} />
      </Menu>
    </div>
  );
}

export default MyCalendar;
