import "../../../styles/comoponents_pages/Customer.scss";
import LayoutWithSidebar from "../../../layout/template/LayoutWithSidebar";
import PageHeader from "../../../components/Header";
import SingleCustomerTable from "../../../components/customer/SingleCustomerTable";
import { BiShoppingBag, BiDollarCircle } from "react-icons/bi";
import { useParams } from "react-router-dom";
import { useGetCustomer } from "../../../services/customer";
import AvatarWithInitials from "../../../components/AvataInitials/AvatarWithInitials";

export default function ViewCustomer() {
  const { customerId } = useParams();

  const { data, status } = useGetCustomer(customerId);
  return (
    <>
      <LayoutWithSidebar>
        <PageHeader title="Customer Information" showOptions={false} />
        {status === "success" ? (
          <>
            <div className="customer_card">
              <div className="customer_card_div1">
                {data.user.avatar ? (
                  <div className="customer_card_div1_innerd">
                    <img src={data.user.avatar} alt="" />
                  </div>
                ) : (
                  <AvatarWithInitials name={data.user.full_name} />
                )}

                <div className="customer_card_user_info">
                  <h3>{data.user.full_name}</h3>
                  <p>{data.user.email}</p>
                  <span>{data.user.phone_number}</span>
                </div>
              </div>
              <div className="customer_card_div2">
                <div className="customer_card_div2_inner_cont">
                  <div className="bag_icon_container">
                    <BiShoppingBag className="shopping_icon" />
                  </div>
                  <div className="customer_card_user_info">
                    <p>Total Orders</p>
                    <h3>{data.user.total_orders}</h3>
                  </div>
                </div>
                <hr className="line_hr" />
                <div className="customer_card_div2_inner_cont">
                  <div className="dollar_icon_container">
                    <BiDollarCircle className="dollar_icon" />
                  </div>
                  <div className="customer_card_user_info">
                    <p>Total Amount spent</p>
                    <h3>${data.user.total_amount_spent} </h3>
                  </div>
                </div>
              </div>
            </div>

            <SingleCustomerTable name={data.user.full_name} />
          </>
        ) : (
          <div>Loading...</div>
        )}
      </LayoutWithSidebar>
    </>
  );
}
