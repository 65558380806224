import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../components/Header";
import SummaryCards from "../components/admin/Summarycards";
import OrdersTable from "../components/admin/orders/OrdersTable";
import QuestionAnalysis from "../components/chart/Analysis";
import LayoutWithSidebar from "../layout/template/LayoutWithSidebar";
import { setCardFilter } from "../redux/slices/AppLayout";
import { useState } from "react";

export default function Dashboard() {
  const dispatch = useDispatch();
  const [date, setDate] = useState();
  const { summaryCardsFilter } = useSelector((state) => state.appLayout);
  const headerOptions = [
    {
      id: "today",
      title: "Today",
      onClick: () => {
        dispatch(setCardFilter("today"));
      },
      isActive: summaryCardsFilter === "today",
    },
    {
      id: "1 week",
      title: "1 week",
      onClick: () => {
        dispatch(setCardFilter("1 week"));
      },
      isActive: summaryCardsFilter === "1 week",
    },
    {
      id: "2 months",
      title: "2 months",
      onClick: () => {
        dispatch(setCardFilter("2 months"));
      },
      isActive: summaryCardsFilter === "2 months",
    },
  ];

  const handleDate = (newDate) => {
    dispatch(
      setCardFilter({
        type: "date",
        start_date: newDate[0],
        end_date: newDate[1],
      })
    );
    setDate(newDate);
  };
  // dayjs(newDate[0]).format("YYYY-MM-DD");
  return (
    <LayoutWithSidebar>
      <PageHeader
        options={headerOptions}
        title="Dashboard"
        handleDate={handleDate}
        value={date}
      />
      <SummaryCards />
      <QuestionAnalysis />
      <OrdersTable link="manager/orders/" isDashboad={true} />
    </LayoutWithSidebar>
  );
}
