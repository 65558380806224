import React, { useState } from "react";
import "../../styles/comoponents_pages/Header.scss";
import MyCalendar from "./Calendar";
import { useSelector } from "react-redux";

function PageHeader({ title, options, showOptions = true, handleDate, value }) {
  const { summaryCardsFilter } = useSelector((state) => state.appLayout);
  const { orderStatus } = useSelector((state) => state.orders);
  const [anchorEl, setAnchorEl] = useState(null);
  return (
    <div className="page-header-container">
      <header>
        <h2 className="page-header-title">{title}</h2>
      </header>
      {showOptions && (
        <div className="page-header-options">
          {options &&
            options.length &&
            options.map((item) => {
              return (
                <div
                  onClick={item.onClick}
                  className={`${
                    item.isActive
                      ? "page-option-card-active"
                      : "page-option-card"
                  }`}
                >
                  <h3>{item.title}</h3>
                </div>
              );
            })}
          <div
            className={`${
              (summaryCardsFilter && summaryCardsFilter.type === "date") ||
              (orderStatus && orderStatus.type === "date")
                ? "page-option-card-active"
                : "page-option-card"
            }`}
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <h3>Date</h3>
          </div>
          <MyCalendar
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            handleDate={handleDate}
            value={value}
          />
          {/* <input type="date" /> */}
        </div>
      )}
    </div>
  );
}

export default PageHeader;
