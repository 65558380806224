import Card from "./Card";
import OrderIcon from "../../assets/icons/OrderIcon";
import DeliveredIcon from "../../assets/DeliveredIcon";
import CompletedIcon from "../../assets/icons/CompletedIcon";
import { useGetCardSummary } from "../../services/dashboard";
import { useSelector } from "react-redux";

export default function SummaryCards() {
  const { summaryCardsFilter } = useSelector((state) => state.appLayout);
  const { data, status } = useGetCardSummary(summaryCardsFilter);
  return status === "pending" ? (
    <section className="loader" style={{ height: "161px" }}>
      <div className="spinner-border" role="status"></div>
    </section>
  ) : (
    <div className="summary-cards-container">
      <Card
        icon={
          <OrderIcon
            color="#D0965A"
            dimension={{ width: "20", height: "20" }}
          />
        }
        title="Total active orders"
        count={data.data.cards_info.processing.count}
        percentage={data.data.cards_info.processing.percentage}
      />
      <Card
        icon={<DeliveredIcon />}
        title="Total delivered orders"
        percentage={data.data.cards_info.on_the_way.percentage}
        count={data.data.cards_info.on_the_way.count}
      />
      <Card
        icon={<CompletedIcon />}
        title={"Total completed orders"}
        count={data.data.cards_info.delivered.count}
        percentage={data.data.cards_info.delivered.percentage}
      />
    </div>
  );
}
