import "../../../styles/comoponents_pages/storeSettings.scss";
import LayoutWithSidebar from "../../../layout/template/LayoutWithSidebar";
import PageHeader from "../../../components/Header";
import CustomerTable from "../../../components/customer/CustomerTable";

export default function Customer() {
  return (
    <>
      <LayoutWithSidebar pageTitle="Customers">
        <PageHeader title="Customers" showOptions={false} />
        <CustomerTable />
      </LayoutWithSidebar>
    </>
  );
}
