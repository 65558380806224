import "../../../styles/comoponents_pages/notifications.scss";
import coverImg2 from "../../../assets/images/courses/biology.webp";
import { useNavigate } from "react-router-dom";

export default function NotificationSummaryMOdal() {
  const navigate = useNavigate();
  return (
    <div
      className="notification-summary-modal-container"
      onClick={(e) => e.stopPropagation()}
    >
      <header className="modal-header">Notification</header>

      <div className="modal-body-area">
        <div className="item">
          <div className="icon">
            <img src={coverImg2} alt="" />
          </div>
          <div className="text">
            <h6>
              order successfully delivered
              <span className="time_stamp">now</span>
            </h6>
            <div className="underlay-text">
              order #2252626666y have been delivered
            </div>
          </div>

          <p>2hr</p>
        </div>

        <div className="modal-footer-area text-center justify-content-center">
          <p onClick={() => navigate("/notifications")}>View all</p>
        </div>
      </div>
    </div>
  );
}
