import { GoLinkExternal } from "react-icons/go";
import { useNavigate } from "react-router-dom";

export function Table({ headers, lists, more, fetchingNextPage }) {
  const navigate = useNavigate();
  return (
    <div className="table-container">
      <table>
        <thead>
          {headers.map((header, index) => (
            <td
              key={index}
              className={`${
                (header.toLowerCase() === "order status" ||
                  header.toLowerCase() === "action") &&
                "text-center"
              }`}
            >
              {header}
            </td>
          ))}
          <td></td>
        </thead>

        <tbody>
          {lists.length
            ? lists.map((list) => {
                return (
                  <tr
                    key={list.id}
                    onClick={() => {
                      navigate(`${list.id}`);
                    }}
                    className="rounded"
                  >
                    <td> {list.full_name} </td>
                    <td>{list.phone_number}</td>
                    <td> {list.total_orders} </td>
                    <td> ${list.total_amount_spent} </td>
                    <td className="custom-space-x">
                      <GoLinkExternal />
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
      {more}
      {fetchingNextPage && <div className="spinner-border" role="status"></div>}
    </div>
  );
}
