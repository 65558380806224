import React from "react";
import RGCoffee from "../../assets/icons/RGCoffeeLogo.svg";
import CoffeCup from "../../assets/images/CoffeeCup.svg";
import "../../styles/comoponents_pages/login.scss";

function AuthLeft() {
  return (
    <div className="auth-left-container">
      <div className="auth-left">
        <figure className="company-logo">
          <img src={RGCoffee} alt="Righteous Grounds Coffee logo" />
        </figure>
        <h2 className="auth-heading">Righteous Grounds Coffee Roasters</h2>
        <center className="coffee-cup-container">
          <img src={CoffeCup} className="coffee-cup" alt="" />
        </center>
      </div>
    </div>
  );
}

export default AuthLeft;
