import { createSlice } from "@reduxjs/toolkit";

export const OrdersSlice = createSlice({
  name: "ordersSlice",
  initialState: {
    orderStatus: null,
    orders: [],
  },
  reducers: {
    setOrderStatus(state, action) {
      state.orderStatus = action.payload;
    },
    setOrders(state, action) {
      state.orders = action.payload;
    },
  },
});

export const { setOrderStatus, setOrders } = OrdersSlice.actions;

export default OrdersSlice.reducer;
