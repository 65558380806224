import Head from "../../layout/head/Head";
import "../../styles/comoponents_pages/login.scss";
import AuthLeft from "./AuthLeft";

export default function AuthWrapper({ children, bottomContent }) {
  return (
    <>
      <Head title="Authentication" />
      <div className="login-page">
        <AuthLeft />
        <div className="login-container">
          <div className="form-container">
            <section className="form-section">{children}</section>
            {bottomContent}
          </div>
        </div>
      </div>
    </>
  );
}
