import React from "react";

function AvatarWithInitials({ name }) {
  function getInitials() {
    return name
      .split(" ")
      .map((word) => word[0])
      .join("")
      .toUpperCase();
  }

  return <div className="avatar-initials">{getInitials()}</div>;
}

export default AvatarWithInitials;
