import React, { useState } from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import { useGetCategory } from "../../../services/products";

function SelectCategory({ handleCat, cat }) {
  const [show, setShow] = useState(false);

  const { data, status } = useGetCategory();
  const handleToggle = () => setShow(!show);

  return (
    <Dropdown
      as={ButtonGroup}
      show={show}
      onToggle={() => setShow(!show)}
      style={{ width: "100%" }}
    >
      <span className="anchor-category" onClick={handleToggle}>
        {cat.name}
      </span>

      <Dropdown.Menu>
        {status === "pending" ? (
          <div className="spinner-border" role="status"></div>
        ) : (
          <div>
            {data &&
              data.data.map((cat) => {
                return (
                  <Dropdown.Item key={cat.id} onClick={() => handleCat(cat)}>
                    {cat.name}
                  </Dropdown.Item>
                );
              })}
          </div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default SelectCategory;
