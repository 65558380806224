import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./slices/AppLayout";
import productSlice from "./slices/ProductSlice";
import ordersSlice from "./slices/Orders";

export default configureStore({
  reducer: {
    appLayout: appReducer,
    productSlice: productSlice,
    orders: ordersSlice,
  },
});
