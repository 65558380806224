import "../../../styles/comoponents_pages/login.scss";
import { useState } from "react";
import AuthWrapper from "../AuthWrapper";
import { useNavigate } from "react-router-dom";
import EnterOtp from "./EnterOtp";
import Success from "./Success";
import Input from "../../../components/Input";
import { useRegisterService } from "../../../services/auth";
import { useMutation } from "@tanstack/react-query";

export default function Register() {
  const navigate = useNavigate();

  const onRegister = useMutation({
    mutationFn: useRegisterService,
    onSuccess: () => {
      setRegPage((prev) => prev + 1);
    },
  });

  const [userInfo, setUserInfo] = useState({
    name: "",
    phone_number: "",
    email: "",
    password: "",
    password_confirmation: "",
  });
  const [regPage, setRegPage] = useState(0);

  const handleRegistration = async (e) => {
    e.preventDefault();
    await onRegister.mutateAsync(userInfo);
  };

  return (
    <AuthWrapper
      bottomContent={
        regPage === 0 && (
          <div className="signup pointer">
            Already have an account?{" "}
            <span
              className="primary-color"
              onClick={() => navigate("/auth/login")}
            >
              Sign in
            </span>
          </div>
        )
      }
    >
      {regPage === 0 && (
        <div>
          <center>
            <header className="header-text">
              <h2>Create an account</h2>
            </header>
          </center>
          <form action="" className="login-form">
            <Input
              type="text"
              label="Full name"
              input={userInfo.name}
              setInput={(input) => setUserInfo({ ...userInfo, name: input })}
            />
            <Input
              type="text"
              label="Phone Number"
              input={userInfo.phone_number}
              setInput={(input) =>
                setUserInfo({ ...userInfo, phone_number: input })
              }
            />
            <Input
              type="email"
              label="Email address"
              input={userInfo.email}
              setInput={(input) => setUserInfo({ ...userInfo, email: input })}
            />
            <Input
              type="password"
              label="Create password"
              input={userInfo.password}
              setInput={(input) =>
                setUserInfo({ ...userInfo, password: input })
              }
            />
            <Input
              type="password"
              label="Confirm Password"
              input={userInfo.password_confirmation}
              setInput={(input) =>
                setUserInfo({ ...userInfo, password_confirmation: input })
              }
            />

            <div className="button-section">
              <button
                className="continue"
                onClick={(e) => handleRegistration(e)}
              >
                Continue
              </button>
            </div>
          </form>
        </div>
      )}
      {regPage === 1 && (
        <EnterOtp onVerify={(otp) => setRegPage((prev) => prev + 1)} />
      )}

      {regPage === 2 && (
        <Success
          title="Congratulations, your account is activated now"
          body=""
          btnTitle="Go to homepage"
          handleClick={() => navigate("/auth/login")}
        />
      )}
    </AuthWrapper>
  );
}
