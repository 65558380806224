import React from "react";

function CloseSideBar() {
  return (
    <svg
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.25003 11.5C1.03753 11.5 0.859406 11.428 0.715656 11.2842C0.571906 11.1404 0.500031 10.9622 0.500031 10.7496C0.500031 10.537 0.571906 10.3589 0.715656 10.2154C0.859406 10.0718 1.03753 9.99998 1.25003 9.99998H11.9615C12.174 9.99998 12.3522 10.0719 12.4959 10.2157C12.6397 10.3595 12.7115 10.5377 12.7115 10.7503C12.7115 10.9629 12.6397 11.141 12.4959 11.2846C12.3522 11.4282 12.174 11.5 11.9615 11.5H1.25003ZM15.9192 10.0211L12.5212 6.63265C12.3404 6.45188 12.25 6.24099 12.25 5.99998C12.25 5.75896 12.3404 5.54807 12.5212 5.3673L15.9192 1.98845C16.0577 1.85 16.2317 1.77917 16.4413 1.77595C16.6509 1.77275 16.8282 1.84358 16.9731 1.98845C17.1179 2.13332 17.1904 2.30896 17.1904 2.51538C17.1904 2.72178 17.1179 2.89741 16.9731 3.04228L13.9865 5.99998L16.9731 8.9673C17.1179 9.10362 17.1904 9.27712 17.1904 9.4878C17.1904 9.69847 17.1179 9.87623 16.9731 10.0211C16.8367 10.1596 16.6632 10.2288 16.4526 10.2288C16.2419 10.2288 16.0641 10.1596 15.9192 10.0211ZM1.25003 6.74995C1.03753 6.74995 0.859406 6.67805 0.715656 6.53425C0.571906 6.39043 0.500031 6.21223 0.500031 5.99965C0.500031 5.78705 0.571906 5.60896 0.715656 5.46538C0.859406 5.32179 1.03753 5.25 1.25003 5.25H9.03848C9.25096 5.25 9.42908 5.3219 9.57283 5.4657C9.71658 5.60952 9.78846 5.78772 9.78846 6.0003C9.78846 6.2129 9.71658 6.39099 9.57283 6.53458C9.42908 6.67816 9.25096 6.74995 9.03848 6.74995H1.25003ZM1.25003 1.99998C1.03753 1.99998 0.859406 1.92807 0.715656 1.78425C0.571906 1.64045 0.500031 1.46225 0.500031 1.24965C0.500031 1.03707 0.571906 0.858984 0.715656 0.715401C0.859406 0.571801 1.03753 0.5 1.25003 0.5H11.9615C12.174 0.5 12.3522 0.571909 12.4959 0.715726C12.6397 0.859542 12.7115 1.03774 12.7115 1.25033C12.7115 1.46293 12.6397 1.64102 12.4959 1.7846C12.3522 1.92818 12.174 1.99998 11.9615 1.99998H1.25003Z"
        fill="#5A524A"
      />
    </svg>
  );
}

export default CloseSideBar;
