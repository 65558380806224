import React from "react";

function OpenSideBar() {
  return (
    <svg
      width="19"
      height="13"
      viewBox="0 0 19 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.11682 12.6209C0.885641 12.6209 0.691862 12.5427 0.535478 12.3862C0.379095 12.2298 0.300903 12.0359 0.300903 11.8046C0.300903 11.5734 0.379095 11.3796 0.535478 11.2234C0.691862 11.0672 0.885641 10.9891 1.11682 10.9891H17.979C18.2101 10.9891 18.4039 11.0673 18.5603 11.2238C18.7167 11.3802 18.7949 11.5741 18.7949 11.8054C18.7949 12.0366 18.7167 12.2304 18.5603 12.3866C18.4039 12.5428 18.2101 12.6209 17.979 12.6209H1.11682ZM1.11682 7.307C0.885641 7.307 0.691862 7.22879 0.535478 7.07235C0.379095 6.91589 0.300903 6.72203 0.300903 6.49077C0.300903 6.25948 0.379095 6.06574 0.535478 5.90954C0.691862 5.75333 0.885641 5.67523 1.11682 5.67523H17.979C18.2101 5.67523 18.4039 5.75345 18.5603 5.90989C18.7167 6.06634 18.7949 6.26021 18.7949 6.49147C18.7949 6.72276 18.7167 6.9165 18.5603 7.0727C18.4039 7.2289 18.2101 7.307 17.979 7.307H1.11682ZM1.11682 1.99313C0.885641 1.99313 0.691862 1.9149 0.535478 1.75844C0.379095 1.60201 0.300903 1.40814 0.300903 1.17686C0.300903 0.945593 0.379095 0.751859 0.535478 0.595657C0.691862 0.439437 0.885641 0.361328 1.11682 0.361328H17.979C18.2101 0.361328 18.4039 0.439555 18.5603 0.596011C18.7167 0.752467 18.7949 0.946329 18.7949 1.17759C18.7949 1.40888 18.7167 1.60262 18.5603 1.75882C18.4039 1.91503 18.2101 1.99313 17.979 1.99313H1.11682Z"
        fill="#5A524A"
      />
    </svg>
  );
}

export default OpenSideBar;
