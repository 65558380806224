import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import requestNew from "../app/requestNew";

export const useGetReviews = () => {
  const getReviews = async ({ pageParam }) => {
    const data = await requestNew({
      url: "admin/review/get-all",
      method: "GET",
      params: {
        page: pageParam || 1,
      },
    });
    return data.data;
  };
  return useInfiniteQuery({
    queryKey: ["reviews"],
    queryFn: getReviews,
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      const { data } = lastPage;
      if (!lastPage.data || lastPage.data.length < data.per_page) {
        return false;
      }
      return allPages.length + 1;
    },
  });
};

const flagReview = ({ id, flag }) => {
  const request = requestNew({
    url: `admin/review/${id}/flag`,
    params: { flag },
    method: "PATCh",
  });
  return request;
};

export const useFlagReview = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: flagReview,
    onSuccess: () => {
      queryClient.invalidateQueries(["reviews"]);
    },
  });
};
