import { createSlice } from "@reduxjs/toolkit";
import { STORAGE_KEYS } from "../../constants/constants";

const user = JSON.parse(localStorage.getItem(STORAGE_KEYS.USER) || '""');
export const AppLayout = createSlice({
  name: "appLayout",
  initialState: {
    showFullSidebar: true,
    user: user,
    summaryCardsFilter: null,
    globalSearch: "",
    settingPage: "profile",
    chartFilter: "weekly",
  },
  reducers: {
    setShowFullSideBar(state, action) {
      state.showFullSidebar = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setCardFilter(state, action) {
      state.summaryCardsFilter = action.payload;
    },
    setGlobalSearch(state, action) {
      state.globalSearch = action.payload;
    },
    setSettingPage(state, action) {
      state.settingPage = action.payload;
    },
    setChartFilter(state, action) {
      state.chartFilter = action.payload;
    },
  },
});

export const {
  setShowFullSideBar,
  setUser,
  setCardFilter,
  setGlobalSearch,
  setSettingPage,
  setChartFilter,
} = AppLayout.actions;

export default AppLayout.reducer;
