import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import store from "./redux/store";
import { Provider } from "react-redux";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";

const onError = (error) => {
  const typedError = error;
  let title = "";
  let body = "";

  if (!error) {
    title = "Oops! An internal server error occurred.";
    showToast("error", title, body);
    return;
  }

  if (typedError.status === 403) {
    title = "Oops! You are not authorized to perform this action.";
  } else if (typedError.status === 401) {
    title = "Oops! You are no longer authenticated.";
    clearUserFromLS();
    window.location.href = "/auth/login";
    return;
  } else if (typedError.status === 500 || !typedError) {
    title = "Oops! An internal server error occurred.";
  } else if (typedError.status === 404) {
    title = "Oops! Resource not found.";
  } else if (
    typedError.status === 422 &&
    typedError.data &&
    typedError.data.message &&
    typedError.data.message.title
  ) {
    title = typedError.data.message.title;
  } else if (typedError.data && typedError.data.message) {
    title = typedError.data.message.title;
    body = typedError.data.message.body;
  } else {
    title =
      typedError.statusText || typedError.message || "Oops! An error occurred.";
  }

  showToast("error", title, body);
};

const onSuccess = (data) => {
  let title = "";
  let body = "";

  if (!data || !data.message || !data.message.title) {
    return;
  }

  const typedSuccess = data;
  const typedTaskData = data;

  title = typedSuccess.message.title;
  body = typedSuccess.message.body || "";

  showToast("success", title, body, typedTaskData.data.task);
};

function showToast(type, title, body, taskData) {
  // Implement your toast rendering logic here
  console.log(
    `Type: ${type}, Title: ${title}, Body: ${body}, Task Data: ${taskData}`
  );
}

function clearUserFromLS() {
  // Implement logic to clear user data from local storage
  console.log("Clearing user data from local storage");
}

// Your QueryClient initialization
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0, // 24 hours
    },
  },
  mutationCache: new MutationCache({
    onError,
    onSuccess,
  }),
  queryCache: new QueryCache({
    onError,
  }),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
