import "../../../styles/comoponents_pages/Peference.scss";
import { BsPencil } from "react-icons/bs";
import React, { useEffect, useState } from "react";
import {
  useEditBusinessPrefferencesService,
  useGetBusinesssPreferences,
} from "../../../services/auth";
import { TfiSave } from "react-icons/tfi";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";

const notify = () => toast("Preference updated successfully");

const paymentMethods = [
  {
    id: "transfer",
    title: "Transfer",
  },
  {
    id: "card",
    title: "Card",
  },
  {
    id: "bank",
    title: "Bank",
  },
];

export default function PreferenceSettings() {
  const [preferences, setPreferences] = useState(null);
  const [editMin, setEditMin] = useState(false);
  const { data, status, isRefetching } = useGetBusinesssPreferences();

  const editProfile = useMutation({
    mutationFn: useEditBusinessPrefferencesService,
    onSuccess: () => {
      notify();
    },
  });

  useEffect(() => {
    if (data) {
      const {
        email_notifications,
        sms_notifications,
        personal_sms_notifications,
        payment_methods,
        minimum_order,
      } = data.preference;
      const prefObj = {
        email_notifications,
        sms_notifications,
        personal_sms_notifications,
        payment_methods,
        minimum_order,
      };
      setPreferences(prefObj);
    }
  }, [data]);

  const editPreference = async (key, value, isMethods) => {
    if (!isMethods) {
      const newData = {
        ...preferences,
        [key]: value,
      };
      setPreferences(newData);
      await editProfile.mutateAsync(newData);
    } else {
      const newData = {
        ...preferences,
        payment_methods: preferences.payment_methods.filter(
          (item) => item !== value
        ),
      };
      setPreferences(newData);
      await editProfile.mutateAsync(newData);
    }
  };

  const editMinimumOrder = async () => {
    if (data.preference.minimum_order !== preferences.minimum_order) {
      await editProfile.mutateAsync(preferences);
    }
    setEditMin(false);
  };

  return (
    <>
      {status === "pending" || isRefetching ? (
        <section className="loader" style={{ height: "350px" }}>
          <div className="spinner-border" role="status"></div>
        </section>
      ) : (
        preferences && (
          <div className="preference">
            <div className="preference_grid">
              <div className="preference_div1">
                <h3>Notifications </h3>
                <p>
                  see who recieved notifcation and <br /> what notification are
                  sent
                </p>
              </div>

              <div className="preference_div2">
                <div className="preference_div2_innerd">
                  <div>
                    <input
                      type="checkbox"
                      checked={Number(preferences.email_notifications)}
                      onChange={() =>
                        editPreference(
                          "email_notifications",
                          preferences.email_notifications === "1" ? "0" : "1",
                          false
                        )
                      }
                    />
                  </div>

                  <div className="preference_notification_type">
                    <h3>Customer Email Notification</h3>
                    <p>
                      By selecting this,customer will recieve email noification
                    </p>
                  </div>
                </div>

                <div className="preference_div2_innerd2">
                  <div>
                    <input
                      type="checkbox"
                      checked={Number(preferences.personal_sms_notifications)}
                      onChange={() =>
                        editPreference(
                          "personal_sms_notifications",
                          preferences.personal_sms_notifications === "1"
                            ? "0"
                            : "1",
                          false
                        )
                      }
                    />
                  </div>

                  <div className="preference_notification_type">
                    <h3>Customer SMS Notification</h3>
                    <p>
                      By selecting this,customer will recieve SMS noification{" "}
                    </p>
                  </div>
                </div>

                <div className="preference_div2_innerd2">
                  <div>
                    <input
                      type="checkbox"
                      checked={Number(preferences.sms_notifications)}
                      onChange={() =>
                        editPreference(
                          "sms_notifications",
                          preferences.sms_notifications === "1" ? "0" : "1",
                          false
                        )
                      }
                    />
                  </div>

                  <div className="preference_notification_type">
                    <h3>Personal SMS Notification</h3>
                    <p> you will be able to recieve SMS notification </p>
                  </div>
                </div>
              </div>
            </div>
            <hr className="preference_hr" />

            <div className="preference_grid2">
              <div className="preference_div1">
                <h3>Payment Methods </h3>
                <p>
                  These will be the payment methods that your <br /> customer
                  will be able to use
                </p>
              </div>

              <div className="preference_grid2_div2">
                {paymentMethods.map((method) => {
                  return (
                    <div className="preference_grid2_div2_innerd2">
                      <input
                        type="checkbox"
                        checked={preferences.payment_methods.includes(
                          method.id
                        )}
                        onChange={() =>
                          editPreference("method", method.id, true)
                        }
                      />
                      <h3>{method.title}</h3>
                    </div>
                  );
                })}
              </div>
            </div>

            <hr className="preference_hr" />

            <div className="preference_grid3">
              <div className="preference_div1">
                <h3>Mininum Orders </h3>
                <p>
                  This would be the basket amount your <br /> customer will be
                  able to preceed with
                </p>
              </div>

              <div className="preference_grid3_div2">
                <div className="preference_grid3_mininum_price">
                  <div>
                    <span style={{ color: !editMin ? "#B0B0B0" : "" }}>$</span>
                    <input
                      type="text"
                      className={`${editMin ? "edit-active" : "edit-inactive"}`}
                      value={`${preferences.minimum_order}`}
                      disabled={!editMin}
                      autoFocus={editMin}
                      onChange={(e) => {
                        setPreferences((prev) => {
                          return { ...prev, minimum_order: e.target.value };
                        });
                      }}
                    />
                  </div>
                  <div style={{ cursor: "pointer" }}>
                    {editMin ? (
                      <TfiSave onClick={editMinimumOrder} />
                    ) : (
                      <BsPencil
                        className="preference_grid3_pen"
                        onClick={() => setEditMin(true)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <hr className="preference_hr" />

            {/* <div className="preference_grid4">
              <div className="preference_div1">
                <h3>Delete Account </h3>
                <p>
                  Your account will be perrmanetly removed <br /> and will be
                  unaccesible
                </p>
              </div>

              <div className="preference_grid4_div2">
                <span>
                  <BsTrash3 /> Delete Account
                </span>
              </div>
            </div> */}
          </div>
        )
      )}
    </>
  );
}
