import { Navigate, createBrowserRouter } from "react-router-dom";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/onboarding/Register";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ChangePassword from "./pages/auth/ChangePassword";
import Dashboard from "./pages/Dashboard";
import Products from "./pages/managers/Products/Products";
import SingleProduct from "./pages/managers/Products/SingleProduct";
import AddProduct from "./pages/managers/Products/AddProduct";
import Customer from "./pages/managers/Customers/Customer";
import ViewCustomer from "./pages/managers/Customers/ViewCustomer";
import Orders from "./pages/managers/Orders/Orders";
import { CustomerOrder } from "./components/admin/customers/CustomerOrder";
import { Reviews } from "./components/admin/reviews";
import Settings from "./pages/Settings";
import Notifications from "./pages/notifications";

export const routes = (user) => {
  return createBrowserRouter([
    {
      path: "/auth",
      element: user && <Navigate to="/" />,
      children: [
        { path: "login", element: <Login /> },
        { path: "signup", element: <Register /> },
        { path: "forgot-password", element: <ForgotPassword /> },
        { path: "forgot", element: <ForgotPassword /> },
        { path: "change-password", element: <ChangePassword /> },
      ],
    },
    {
      path: "/",
      element: user ? <Dashboard /> : <Navigate to="/auth/login" />,
    },
    {
      path: "/manager",
      // element: user ? <Dashboard /> : <Navigate to="/auth/login" />,
      children: [
        { path: "products", element: <Products /> },
        { path: "products/:productId", element: <SingleProduct /> },
        { path: "products/create", element: <AddProduct /> },
        { path: "customer", element: <Customer /> },
        { path: "customer/:customerId", element: <ViewCustomer /> },
        { path: "orders", element: <Orders /> },
        { path: "orders/:orderId", element: <CustomerOrder /> },
        { path: "reviews", element: <Reviews /> },
        { path: "settings", element: <Settings /> },
      ],
    },
    {
      path: "/notifications",
      element: user ? <Notifications /> : <Navigate to="/auth/login" />,
    },
  ]);
};
