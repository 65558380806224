import React from "react";

function Success({ title, body, btnTitle, handleClick }) {
  return (
    <div className="success-page">
      <span className="success-circle"></span>
      <h2>{title}</h2>
      <p>{body}</p>
      <button onClick={handleClick}>{btnTitle}</button>
    </div>
  );
}

export default Success;
