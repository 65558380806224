import dayjs from "dayjs";
import { BsStarFill } from "react-icons/bs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useState } from "react";

dayjs.extend(relativeTime);

export function ReviewsCard({ review }) {
  const [replyReview] = useState(false);

  return (
    <div className="review-container">
      {/* flex sections */}
      <div className="review-flex-section">
        <div className="review-stars">
          {Array.from({ length: review.rating }, (_) => (
            <BsStarFill width={25} height={25} key={review.id} fill="#D09559" />
          ))}
          {Array.from({ length: 5 - review.rating }, (_) => (
            <BsStarFill width={25} height={25} key={review.id} fill="#E0DDD9" />
          ))}
        </div>
        <div className="review-date">{dayjs(review.created_at).fromNow()}</div>
      </div>
      {/* Review Body */}
      <p className="comment-body">{review.comment}</p>
      <div className="review-flex-section">
        <div className="review-user">
          <div className="user-initials">
            {review.user.full_name.charAt(0).toUpperCase()}
          </div>
          <span className="username-comments">{review.user.full_name}</span>
        </div>
        {/* <div className="review-icons">
          {review.review_comments.length > 0 ? (
            <div className="flex">
              <MdOutlineFeedback
                className="flex"
                onClick={() => setReplyReview(!replyReview)}
              />
              <span>View reply</span>
            </div>
          ) : (
            <MdOutlineFeedback
              className="flex"
              onClick={() => setReplyReview(!replyReview)}
            />
          )}
          <BsFlag
            onClick={() =>
              mutate({ id: review.id, flag: review.flagged === "0" ? 1 : 0 })
            }
            className={review.flagged === "1" ? "flagged" : ""}
          />
        </div> */}
      </div>
      {replyReview && (
        <div className="w-full  border-l">
          <textarea
            placeholder="Write a reply here..."
            className="full-textarea"
          ></textarea>
          <div className="btn flex">
            <button className="unstyled-btn" type="button">
              cancel
            </button>
            <button className="unstyled-btn" type="button">
              send
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
