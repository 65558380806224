import dayjs from "dayjs";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import Head from "../../../layout/head/Head";
import LayoutWithSidebar from "../../../layout/template/LayoutWithSidebar";
import Mastercard from "../../../assets/icons/cards/mastercard-nowe-logo.png";
import { useNavigate, useParams } from "react-router-dom";
import {
  useEditOrderStatus,
  useGetOrderDetails,
} from "../../../services/orders";
import EditOrderStatus from "../orders/EditOrderStatus";
import { useEffect, useState } from "react";
import AvatarWithInitials from "../../AvataInitials/AvatarWithInitials";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";

const notify = () => toast("Order status updated successfully");

function capitalizeWord(word) {
  if (!word) {
    return "";
  }
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

export function CustomerOrder() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [editStatus, setEditStatus] = useState(false);
  const [order, setOrder] = useState(null);
  const { orderStatus } = useSelector((state) => state.orders);
  const { orderId } = useParams();
  const { data, status } = useGetOrderDetails(orderId);

  const editOrderStatus = useEditOrderStatus(orderStatus);

  useEffect(() => {
    if (data) {
      setOrder(data.order);
      console.log(data.order.user);
    }
  }, [data, status]);

  const handleSelectStatus = (orderStatus) => {
    setOrder((prev) => {
      return { ...prev, delivery_status: orderStatus.value };
    });
    const data = {
      delivery_status: orderStatus.id,
      id: orderId,
    };
    editOrderStatus.mutate(data, {
      onSuccess: () => {
        notify();
        queryClient.invalidateQueries(["orders"]);
      },
      // To be removed/edited
      onError: () => {
        notify();
      },
    });
  };

  return (
    <>
      <Head title="Order History" />
      <LayoutWithSidebar pageTitle="Order History">
        <div className="order-table-container">
          <div className="order-table-header">
            <label>
              <h1 className="decor-text">Order History</h1>
            </label>
            {status === "pending" ? (
              <div className="spinner-border" role="status"></div>
            ) : (
              <div className="order-table-banner">
                <span className="decor-text">
                  Order ID: {order && order.order_ref}
                </span>
                <span className="decor-text">
                  Order Date:
                  {dayjs(order && order.created_at).format("MMM DD, YYYY")}
                </span>
                <div>
                  <span className="decor-text">Status:</span>
                  <div
                    className="drop-down-comp"
                    onClick={(e) => setEditStatus(e.currentTarget)}
                  >
                    <span className="decor-text">
                      {order && capitalizeWord(order.delivery_status)}
                    </span>
                    <MdOutlineKeyboardArrowDown />
                  </div>
                  <EditOrderStatus
                    anchorEl={editStatus}
                    setAnchorEl={setEditStatus}
                    handleSelect={handleSelectStatus}
                  />
                </div>
              </div>
            )}
          </div>

          {/* {status === "pending" ? (
            <div className="spinner-border" role="status"></div>
          ) : ( */}
          <div className="order-table-body">
            <div className="order-details-card">
              <label>Order Items</label>
              <table className="order-details-table">
                <thead>
                  <tr>
                    <td id="order-item">Item</td>
                    <td>Price</td>
                    <td>Quantity</td>
                    <td>Subtotal</td>
                  </tr>
                </thead>
                <tbody>
                  {status === "pending" ? (
                    <div className="spinner-border" role="status"></div>
                  ) : (
                    order &&
                    order.order_items.map((order) => {
                      return (
                        <tr>
                          <td className="order-details">
                            <div className="order-img-container">
                              <img
                                src={order.product.product_images[0].image}
                                alt="order_img"
                              />
                            </div>
                            <div className="order-composition">
                              <span>{order && order.product.name}</span>
                              <div className="order-ingredients">
                                25% Milk, 0% sugar
                              </div>
                            </div>
                          </td>
                          <td>
                            <span>${order && order.price}</span>
                          </td>
                          <td>
                            <span>{order && order.quantity}x</span>
                          </td>
                          <td>
                            <span>
                              ${order && order.price * order.quantity}
                            </span>
                          </td>
                        </tr>
                      );
                    })
                  )}
                  {/* {status === "pending" ? (
                    <div className="spinner-border" role="status"></div>
                  ) : (
                    <tr>
                      <td className="order-details">
                        <div className="order-img-container">
                          <img src={ExpressoCoffee} alt="order_img" />
                        </div>
                        <div className="order-composition">
                          <span>Expresso</span>
                          <div className="order-ingredients">
                            25% Milk, 0% sugar
                          </div>
                        </div>
                      </td>
                      <td>
                        <span>$2.99</span>
                      </td>
                      <td>
                        <span>1x</span>
                      </td>
                      <td>
                        <span>$15.98</span>
                      </td>
                    </tr>
                  )} */}
                </tbody>
              </table>
              <div className="payment-summary">
                <label>Payment Summary</label>
                <table>
                  <tbody>
                    <tr>
                      <td className="summary-table-body">
                        <span>Subtotal</span>
                        <span>${order && order.subtotal}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Delivery fee</span>
                        <span>${order && order.delivery_fee}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Service fee</span>
                        <span>${order && order.service_fee}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Tip</span>
                        <span>$0.0</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Order total</span>
                        <span>${order && order.total}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {order && order.payment_status !== "unpaid" && (
                <div className="payment-method">
                  <div>
                    <lable className="method-labels">Payment Method</lable>
                    <div className="method-container">
                      <img src={Mastercard} alt="card_type_img" />
                      <span>Mastercard ****9245 </span>
                    </div>
                  </div>
                  <div>
                    <lable className="method-labels">
                      Payment date and time
                    </lable>
                    <div className="method-container">
                      {dayjs().format("DD/MM/YYYY HH:MM A")}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="customer-details-card">
              <label>Customer Info</label>
              <div className="customer-profile">
                <div className="profile-bio">
                  <div className="profile-info">
                    {order && order.user.avatar ? (
                      <div className="profile-img-container">
                        <img
                          src={order && order.user.avatar}
                          alt="profile_img"
                        />
                      </div>
                    ) : (
                      order && (
                        <AvatarWithInitials
                          name={order && order.user.full_name}
                        />
                      )
                    )}
                    <p style={{ font: "15px", marginBottom: "0" }}>
                      {order && order.user.full_name}
                    </p>
                  </div>

                  <span>{order && order.user.name}</span>
                </div>
                <div
                  className="customer-profile-btn"
                  onClick={() => navigate(`/manager/customer/${order.user.id}`)}
                >
                  <span>View profile</span>
                </div>
              </div>
              <div className="customer-phone">
                <p>Phone number</p>
                <span>{order && order.user.phone_number}</span>
              </div>
              <div className="customer-location">
                <p>Delivery Location</p>
                <span>{order && order.delivery_details} </span>
              </div>
            </div>
          </div>
          {/* )} */}
        </div>
      </LayoutWithSidebar>
    </>
  );
}
