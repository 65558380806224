import React, { useState } from "react";
import CenteredPopup from "../../../components/modal/Modal";
import FactCheck from "../../../assets/icons/FactCheck";
import { useDeleteProduct } from "../../../services/products";
import { useNavigate } from "react-router-dom";

function DeleteProduct({ modalShow, setModalShow, productId }) {
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState(false);
  const { mutate: deleteProduct } = useDeleteProduct();
  const handleDelete = () => {
    setIsloading(true);
    deleteProduct(productId, {
      onSuccess: () => {
        setIsloading(false);
        setModalShow(false);
        navigate("/manager/products", { replace: true });
      },
    });
  };

  return (
    <CenteredPopup
      show={modalShow}
      icon={!isLoading ? <FactCheck /> : null}
      onHide={() => setModalShow(false)}
      children={
        !isLoading ? (
          <>
            <h4>Are you sure you want to delete these products?</h4>
            <p>
              Customers will no longer have access to these products once you
              delete them
            </p>
          </>
        ) : (
          <div className="spinner-border" role="status"></div>
        )
      }
      footer={
        !isLoading ? (
          <>
            <span
              className="footer-btn cancel-btn"
              onClick={() => setModalShow(false)}
            >
              Cancel
            </span>
            <span className="footer-btn action-btn" onClick={handleDelete}>
              Delete
            </span>
          </>
        ) : null
      }
    />
  );
}

export default DeleteProduct;
