import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function RcTooltip({ direction, title, children }) {
  return (
    <OverlayTrigger
      placement={direction}
      overlay={<Tooltip id="tooltip1">{title}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  );
}

export default RcTooltip;
