import { useEffect, useState } from "react";
import { EmptyReviewsCard } from "./EmptyReviewsCard";
import Head from "../../../layout/head/Head";
import LayoutWithSidebar from "../../../layout/template/LayoutWithSidebar";
import { ReviewsCard } from "./Reviewcards";
import { useGetReviews } from "../../../services/review";
import { useInView } from "react-intersection-observer";

export function Reviews() {
  const { ref, inView } = useInView();
  const [reviews, setReviews] = useState([]);

  const { data, status, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useGetReviews();

  useEffect(() => {
    if (status === "success") {
      console.log(data);
      const review = data.pages.flatMap((page) => page.reviews.data);
      setReviews(review);
    }
  }, [status, data]);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  return (
    <>
      <Head title="Reviews" />
      <LayoutWithSidebar pageTitle="Reviews">
        <div className="reviews-container">
          {status === "pending" ? (
            <div className="spinner-container">
              <div className="spinner-border" role="status"></div>
            </div>
          ) : reviews.length > 0 ? (
            <>
              {reviews.map((review) => (
                <ReviewsCard key={review.id} review={review} />
              ))}
              {isFetchingNextPage && (
                <div className="spinner-border" role="status"></div>
              )}
            </>
          ) : (
            <EmptyReviewsCard />
          )}
          {!isFetchingNextPage && status !== "pending" && (
            <button style={{ opacity: 0 }} onClick={fetchNextPage} ref={ref}>
              Load More
            </button>
          )}
        </div>
      </LayoutWithSidebar>
    </>
  );
}
