import React from "react";

function DeliveredIcon() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.84618 10.3942L5.68797 5.99958L5.24422 6.68656C4.94837 7.14453 4.62211 7.6471 4.26543 8.19425C3.90873 8.7414 3.58302 9.24485 3.28828 9.7046L2.84618 10.3942ZM7.46714 11.9724H1.39932C1.0812 11.9724 0.848866 11.8363 0.702325 11.564C0.555783 11.2917 0.568311 11.0232 0.739908 10.7585L3.7938 5.99958L0.739908 1.24067C0.568311 0.975972 0.555783 0.70693 0.702325 0.433542C0.848866 0.160139 1.0812 0.0234375 1.39932 0.0234375H10.1555C10.4806 0.0234375 10.78 0.103048 11.0537 0.262271C11.3274 0.421479 11.5577 0.629562 11.7444 0.88652L15.3421 5.94569L15.0391 6.37521C14.7734 6.23883 14.5036 6.13009 14.2297 6.04898C13.9558 5.96785 13.6859 5.91647 13.4199 5.89483L10.3222 1.60496H2.84618L5.68797 5.99958L2.84618 10.3942H7.48541C7.4386 10.6556 7.41382 10.9183 7.41105 11.1825C7.40829 11.4466 7.42698 11.7099 7.46714 11.9724ZM12.1933 11.2897L11.8673 10.9588C11.7346 10.8261 11.5759 10.7605 11.3912 10.7622C11.2065 10.7639 11.0478 10.8311 10.915 10.9638C10.7801 11.0988 10.7126 11.2546 10.7126 11.4312C10.7126 11.6078 10.7801 11.7636 10.915 11.8986L11.7754 12.759C11.8939 12.8776 12.034 12.9369 12.1956 12.9369C12.3573 12.9369 12.4975 12.8776 12.6161 12.759L14.6798 10.7319C14.8148 10.5972 14.8823 10.4381 14.8823 10.2543C14.8823 10.0706 14.8148 9.91127 14.6798 9.77631C14.5471 9.64356 14.3884 9.57747 14.2037 9.57802C14.019 9.57858 13.8603 9.64467 13.7275 9.77631L12.1933 11.2897ZM12.7905 15.242C11.6886 15.242 10.7477 14.8502 9.96758 14.0666C9.18749 13.283 8.79745 12.3461 8.79745 11.2559C8.79745 10.1476 9.18745 9.20159 9.96745 8.41777C10.7474 7.63395 11.6908 7.24204 12.7975 7.24204C13.8947 7.24204 14.8356 7.63395 15.6203 8.41777C16.4051 9.20159 16.7974 10.1476 16.7974 11.2559C16.7974 12.3461 16.4051 13.283 15.6203 14.0666C14.8356 14.8502 13.8923 15.242 12.7905 15.242Z"
        fill="#D0965A"
      />
    </svg>
  );
}

export default DeliveredIcon;
