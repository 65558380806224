import "../../../styles/comoponents_pages/storeSettings.scss";
import { BsPencil, BsTrash3 } from "react-icons/bs";
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import {
  useEditBusinessHour,
  useEditPrepTimeService,
  useGetBusinesssHours,
  useGetPreparatoryTime,
} from "../../../services/auth";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { convertTo12HourFormat, isOpenNow } from "../../../helpers/helpers";

const notify = (msg) => toast(msg);

export default function StoreSettings() {
  const queryClient = useQueryClient();
  const [time, setTime] = useState("");
  const [editPrepTime, setEditPrepTime] = useState(false);
  const [businessHr, setBusinessHr] = useState([]);
  const [editMode, seteditMode] = useState(false);
  const { data } = useGetPreparatoryTime();
  const { data: hours, status } = useGetBusinesssHours();
  const editPrep = useMutation({
    mutationFn: useEditPrepTimeService,
    onSuccess: () => {
      notify("Preparatory time updated successfully");
    },
  });

  const editBusinessHour = useMutation({
    mutationFn: useEditBusinessHour,
    onSuccess: () => {
      queryClient.invalidateQueries(["business-hours"]);
      notify("Business hour updated successfully");
    },
  });

  useEffect(() => {
    if (data) {
      setTime(data.preparation_time);
    }
  }, [data]);

  useEffect(() => {
    if (hours) {
      setBusinessHr(hours.business_hours);
    }
  }, [hours]);

  const handlePrepTime = async () => {
    setEditPrepTime(false);
    if (time !== data.preparation_time) await editPrep.mutateAsync(time);
  };

  const switchHr = (day) => {
    const newData = businessHr.map((hr) => {
      if (day === hr.day) {
        console.log(day);
        return { ...hr, is_open: hr.is_open === "1" ? "0" : "1" };
      }
      return hr;
    });
    editBusinessHour.mutateAsync(newData);
    setBusinessHr(newData);
  };

  const editTime = (type, day, val) => {
    const newData = businessHr.map((hr) => {
      if (day === hr.day) {
        if (type === "to") {
          return { ...hr, close_time: val };
        } else {
          return { ...hr, open_time: val };
        }
      }
      return hr;
    });
    setBusinessHr(newData);
  };

  const handleSaveHours = () => {
    editBusinessHour.mutateAsync(businessHr);
  };

  return (
    <>
      <div className="store_settings">
        <div className="store_settings_outline">
          <div className="store_settings_header">
            <div className="store_settings_header_div1">
              <h3>
                Regular Opening <br /> Hours
              </h3>
              <div className="store_settings_header_div1_time">
                <span>
                  {businessHr.length
                    ? isOpenNow(businessHr)
                      ? "Opened"
                      : "Closed"
                    : null}
                </span>
                <p>{convertTo12HourFormat("17:23")}</p>
              </div>
            </div>
            <div className="store_settings_header_div2">
              {editMode ? (
                <div className="pref-btns">
                  <button
                    className="pref-actions pref-cancel"
                    onClick={() => {
                      seteditMode(false);
                      setBusinessHr(hours.business_hours);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="pref-actions pref-save"
                    onClick={handleSaveHours}
                  >
                    Save
                  </button>
                </div>
              ) : (
                <span
                  className="store_settings_header_div2_edit"
                  onClick={() => seteditMode(true)}
                >
                  <BsPencil /> Edit
                </span>
              )}
            </div>
          </div>

          <p className="store_settings_header_div2_p">
            Time Slot (Opening Time and Closing Time)
          </p>

          <>
            {status === "pending" ? (
              <section className="loader" style={{ height: "350px" }}>
                <div className="spinner-border" role="status"></div>
              </section>
            ) : businessHr.length ? (
              businessHr.map((hr) => {
                return (
                  <div className="store_settings_action" key={hr.id}>
                    <p className="store_settings_action_p">{hr.day}</p>
                    <div className="store_settings_action_div1">
                      {editMode ? (
                        <input
                          value={hr.open_time ?? ""}
                          type="time"
                          className="set-time"
                          onChange={(e) =>
                            editTime("from", hr.day, e.target.value)
                          }
                        />
                      ) : (
                        <span className="time">{hr.open_time ?? "-"}</span>
                      )}

                      <span className="time_to">To</span>
                      {editMode ? (
                        <input
                          type="time"
                          className="set-time"
                          value={hr.close_time ?? ""}
                          onChange={(e) =>
                            editTime("to", hr.day, e.target.value)
                          }
                        />
                      ) : (
                        <span className="time">{hr.close_time ?? "-"}</span>
                      )}
                      <div className="store_settings_action_open">
                        <div>
                          <Form>
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              style={{
                                "--bs-active-background-color": "green",
                                "--bs-active-border-color": "green",
                              }}
                              checked={
                                hr.is_open === true || hr.is_open === "1"
                                  ? true
                                  : false
                              }
                              onChange={() => switchHr(hr.day)}
                            />
                          </Form>
                        </div>
                        <span className="open">
                          {hr.is_open ? "Opened" : "Closed"}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : null}
          </>

          {}
        </div>

        <div className="store_settings_outline2">
          <div className="store_settings_header2">
            <h3>Preparation time</h3>
            <div className="store_settings_header2_innerd">
              <div>
                {editPrepTime ? (
                  <input
                    type="text"
                    value={time}
                    placeholder="Time"
                    className="edit-prep-time"
                    autoFocus={true}
                    onChange={(e) => setTime(e.target.value)}
                  />
                ) : (
                  <h3>{time ? `${time}mins` : "-"}</h3>
                )}
              </div>

              {editPrepTime ? (
                <span className="store_edit" onClick={handlePrepTime}>
                  Save
                </span>
              ) : (
                <span
                  className="store_edit"
                  onClick={() => setEditPrepTime(true)}
                >
                  <BsPencil /> Edit
                </span>
              )}

              <span className="store_delete">
                <BsTrash3 /> Delete
              </span>
            </div>
          </div>

          <p className="store_settings_header2_p">
            This is the estimated amount of time to prepare your orders.
          </p>
        </div>
      </div>
    </>
  );
}
