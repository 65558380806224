import EmptyReview from "../../../assets/icons/cards/customer-feedback.png"
export function EmptyReviewsCard() {
  return (
    <div className="empty-review-container" style={{ height: 700 }}>
      <img
        src={EmptyReview}
        alt="reviews_icon"
        style={{ width: 100, height: 100 }}
      />
      <div className="reviews-description">
        <p>No Reviews yet</p>
        <span>All reviews will appear here</span>
      </div>
    </div>
  )
}
