import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../helpers/helpers";
import { MdOutlineOpenInNew } from "react-icons/md";

function capitalizeString(str) {
  // Split the string into words
  const words = str.split(" ");

  // Capitalize the first letter of each word
  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  // Join the words back into a string
  const capitalizedStr = capitalizedWords.join(" ");
  return capitalizedStr;
}

const getTotalPrice = (orders) => {
  const total = orders.reduce((sum, current) => sum + Number(current.price), 0);
  return Number(total.toFixed(2));
};

export function Table({
  headers,
  lists,
  link,
  userName,
  more,
  fetchingNextPage,
}) {
  const navigate = useNavigate();
  return (
    <div className="table-container">
      <table>
        <thead>
          {headers.map((header, index) => (
            <td
              key={index}
              className={`${
                (header.toLowerCase() === "order status" ||
                  header.toLowerCase() === "action") &&
                "text-center"
              }`}
            >
              {header}
            </td>
          ))}
        </thead>

        <tbody>
          {lists.length
            ? lists.map((list, index) => {
                return (
                  <tr
                    key={list.id}
                    onClick={() => {
                      navigate(`${link ?? ""}${list.id}`);
                    }}
                    className="rounded"
                  >
                    <td>{index + 1}</td>
                    <td> {list.order_ref} </td>
                    <td>{formatDate(list.created_at)}</td>
                    <td>
                      <div className="profile">
                        <div className="texts">
                          <div className="name">
                            {userName ?? list.user.full_name}
                          </div>
                        </div>
                      </div>
                    </td>
                    {/* <td>{list.delivery_details}</td> */}
                    <td> ${getTotalPrice(list.order_items)} </td>
                    <td className="status-column text-center">
                      <span
                        className={`all-orders ${
                          list.delivery_status?.toLowerCase() === "new order" &&
                          "new-order"
                        } ${
                          list.delivery_status?.toLowerCase() ===
                            "processing" && "processing-order"
                        } ${
                          list.delivery_status?.toLowerCase() === "completed" &&
                          "completed-order"
                        }`}
                      >
                        {capitalizeString(list.delivery_status)}
                      </span>
                    </td>
                    <td className="text-center">
                      <MdOutlineOpenInNew />
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
      {more}
      {fetchingNextPage && <div className="spinner-border" role="status"></div>}
    </div>
  );
}
