import { useState } from "react";
import Navigation from "../../components/admin/Navigation";
import Sidebar from "../../components/admin/Sidebar";
import NotificationSummaryMOdal from "../../components/admin/notifications/NotificationSummaryModal";
import { useSelector } from "react-redux";
import SettingsSummaryModal from "../../components/admin/settings/SettingsSummaryModal";
import { Toaster } from "react-hot-toast";

export default function LayoutWithSidebar({ children, pageTitle }) {
  const { showFullSidebar } = useSelector((state) => state.appLayout);
  const [showNotifications, setShowNotifications] = useState(true);
  const [showSettingsModal, setShowSettingsModal] = useState(null);
  return (
    <>
      <Toaster />
      <Navigation
        pageTitle={pageTitle}
        showNotifications={showNotifications}
        setShowNotifications={setShowNotifications}
        showSettingsModal={showSettingsModal}
        setShowSettingsModal={setShowSettingsModal}
      />
      <Sidebar />

      <div
        className="template-with-sidebar-body"
        onClick={() => setShowNotifications(false)}
        style={{ left: `calc(${showFullSidebar ? "20" : "5"}% + 20px)` }}
      >
        {children}
      </div>
      <div className="modals-section">
        {showNotifications ? <NotificationSummaryMOdal /> : null}
        {showSettingsModal ? (
          <SettingsSummaryModal
            anchorEl={showSettingsModal}
            setAnchorEl={setShowSettingsModal}
          />
        ) : null}
      </div>
    </>
  );
}
