import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

export const formatDate = (dateString) => {
  return dayjs(dateString).format("DD/MM/YYYY");
};

export const convertTo12HourFormat = (time24) => {
  return dayjs(time24, "HH:mm").format("hh:mm A");
};

export function isOpenNow(businessHours) {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const currentDay = daysOfWeek[new Date().getDay()];
  const currentTime = new Date();

  const getTime = (timeStr) => {
    if (!timeStr) return null;

    const match = timeStr.match(/(\d{1,2})(:\d{2})?([AP]M)/i);
    if (!match) return null;

    let [_, hours, minutes, modifier] = match;
    hours = parseInt(hours, 10);
    minutes = minutes ? parseInt(minutes.slice(1), 10) : 0;

    if (modifier.toUpperCase() === "PM" && hours !== 12) {
      hours += 12;
    } else if (modifier.toUpperCase() === "AM" && hours === 12) {
      hours = 0;
    }

    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  };

  const todayHours = businessHours.find((entry) => entry.day === currentDay);

  if (!todayHours || !todayHours.is_open) {
    return false;
  }

  const openTime = getTime(todayHours.open_time);
  const closeTime = getTime(todayHours.close_time);

  if (!openTime || !closeTime) {
    return false;
  }

  return currentTime >= openTime && currentTime <= closeTime;
}

export function createSizesObject(sizes) {
  const result = {};

  sizes.forEach((item, index) => {
    result[`sizes[${index}]`] = item;
  });

  return result;
}

export function createMilkTypeObject(milkTypes) {
  const result = {};

  milkTypes.forEach((item, index) => {
    result[`milk_types[${index}]`] = item;
  });

  return result;
}