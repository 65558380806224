import { createSlice } from "@reduxjs/toolkit";

export const ProductSlice = createSlice({
  name: "productSlice",
  initialState: {
    deleteProductArr: [],
    selectMode: false,
    catFilter: null,
  },
  reducers: {
    setDelProduct(state, action) {
      if (Array.isArray(action.payload)) {
        state.deleteProductArr = action.payload;
      } else if (state.deleteProductArr.includes(action.payload)) {
        const newArr = state.deleteProductArr.filter(
          (i) => i !== action.payload
        );
        state.deleteProductArr = newArr;
      } else {
        const newArr = [...state.deleteProductArr, action.payload];
        state.deleteProductArr = newArr;
      }
    },
    setToggleSelectMode(state) {
      state.selectMode = !state.selectMode;
    },
    setCatFilter(state, action) {
      state.catFilter = action.payload;
    },
  },
});

export const { setDelProduct, setToggleSelectMode, setCatFilter } =
  ProductSlice.actions;

export default ProductSlice.reducer;
