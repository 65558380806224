import Head from "../../../layout/head/Head";
import { useEffect, useState } from "react";
import DeletePrompt from "../prompt/DeletePrompt";
import EmptyCatalogue from "../../empty_states/EmptyCatalogue";
import { Table } from "./Table";
import { useGetOrders } from "../../../services/orders";
import { useDispatch, useSelector } from "react-redux";
import { setOrders } from "../../../redux/slices/Orders";
import { setGlobalSearch } from "../../../redux/slices/AppLayout";
import { useInView } from "react-intersection-observer";

const tableheaders = [
  "No",
  "Order ID",
  "Date",
  "Customer Name",
  "Order Total",
  "Order Status",
  "Action",
];

export default function OrdersTable({ link, isDashboad = false }) {
  const dispatch = useDispatch();
  const { ref, inView } = useInView();
  const { orderStatus, orders } = useSelector((state) => state.orders);
  const { globalSearch } = useSelector((state) => state.appLayout);
  const [editCourse, setEditCourse] = useState({
    toggle: false,
    toEdit: "",
  });

  const [deleteCourse, setDeleteCourse] = useState({
    deletePrompt: false,
    lessonToDelete: "",
  });

  function handleDeleteCourse() {
    console.log("course deleted");
  }

  const {
    data,
    status,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isRefetching,
  } = useGetOrders(orderStatus, globalSearch);

  useEffect(() => {
    dispatch(setGlobalSearch(""));
  }, [dispatch]);

  useEffect(() => {
    if (inView && hasNextPage && !isDashboad) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage, isDashboad]);

  useEffect(() => {
    if (data) {
      const orders = data.pages.flatMap((page) => page.data.orders.data);
      dispatch(setOrders(orders));
    }
  }, [data, dispatch]);

  return (
    <>
      <Head title="Orders | All orders" />
      <div onClick={() => setEditCourse({ ...editCourse, toEdit: "" })}>
        {status === "pending" || isRefetching ? (
          <div className="spinner-container">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : data && orders.length ? (
          <div
            className="history-table"
            style={{
              border: "1px",
              borderColor: "#E0DDD9",
              borderStyle: "solid",
              margin: "10px 0",
              padding: "20px",
            }}
          >
            <Table
              headers={tableheaders}
              lists={orders}
              link={link}
              more={
                <>
                  {!isFetchingNextPage && status !== "pending" && (
                    <button
                      style={{ opacity: 0 }}
                      onClick={fetchNextPage}
                      ref={ref}
                    >
                      Load More
                    </button>
                  )}
                </>
              }
              fetchingNextPage={isFetchingNextPage}
            />
          </div>
        ) : (
          <EmptyCatalogue
            title="No Orders yet"
            body="All orders will appear here"
            action=""
          />
        )}
      </div>

      <div className="modal">
        <DeletePrompt
          showState={deleteCourse.deletePrompt}
          setShowState={() => {
            setDeleteCourse({
              ...deleteCourse.deletePrompt,
              deletePrompt: false,
            });
          }}
          itemToDelete={"Course"}
          handleDelete={() => {
            handleDeleteCourse();
          }}
        />
      </div>
    </>
  );
}
