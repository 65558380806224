import "../../styles/emptyStates.scss";

export default function EmptyCatalogue({ title, body, action }) {
  return (
    <div className="empty-subject-catalogue">
      <center>
        <header>
          <h5>{title}</h5>
        </header>
        <p>{body}</p>
        {action && <button>{action}</button>}
      </center>
    </div>
  );
}
